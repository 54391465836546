// CreateInterviewModal.jsx
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { supabase } from '../../supabaseConfig';
import PropTypes from 'prop-types';
import {
    X,
    AlertCircle,
    CheckCircle2,
    ArrowRight,
    Building,
    Upload,
    FileText,
    Loader2,
    File,
    Type,
    Sparkles,
    BookOpen,
    AlertTriangle,
    KeyRound
} from 'lucide-react';

// Constants
const DEV_FALLBACK_UUID = '0d41564f-e1d8-4bd8-a087-60784112a2e8';
const isDev = process.env.NODE_ENV === 'development';

// Animation variants
const containerVariants = {
    hidden: {
        opacity: 0,
        scale: 0.95,
        y: 20
    },
    visible: {
        opacity: 1,
        scale: 1,
        y: 0,
        transition: {
            type: "spring",
            stiffness: 300,
            damping: 30,
            duration: 0.4
        }
    },
    exit: {
        opacity: 0,
        scale: 0.95,
        y: 20,
        transition: {
            duration: 0.2
        }
    }
};

const stepVariants = {
    enter: (direction) => ({
        x: direction > 0 ? 50 : -50,
        opacity: 0
    }),
    center: {
        x: 0,
        opacity: 1,
        transition: {
            duration: 0.3
        }
    },
    exit: (direction) => ({
        x: direction < 0 ? 50 : -50,
        opacity: 0,
        transition: {
            duration: 0.3
        }
    })
};

const fadeInUp = {
    initial: {
        opacity: 0,
        y: 20
    },
    animate: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.4
        }
    },
    exit: {
        opacity: 0,
        y: -20,
        transition: {
            duration: 0.3
        }
    }
};

// Data Constants
const COMPANY_OPTIONS = [
    { value: 'Amazon', label: 'Amazon', icon: '🛒', color: '#FF9900' },
    { value: 'Google', label: 'Google', icon: '🔍', color: '#4285F4' },
    { value: 'Microsoft', label: 'Microsoft', icon: '🪟', color: '#00A4EF' },
    { value: 'Meta', label: 'Meta', icon: '📱', color: '#0668E1' },
    { value: 'Apple', label: 'Apple', icon: '🍎', color: '#A2AAAD' },
    { value: 'Netflix', label: 'Netflix', icon: '🎬', color: '#E50914' },
    { value: 'Uber', label: 'Uber', icon: '🚗', color: '#000000' },
    { value: 'Custom', label: 'Custom Company', icon: '✨', color: '#6366F1' }
];

const ROLE_OPTIONS = [
    { value: 'Software Engineer', label: 'Software Engineer', icon: '💻' },
    { value: 'Product Manager', label: 'Product Manager', icon: '📊' },
    { value: 'Data Scientist', label: 'Data Scientist', icon: '📈' },
    { value: 'Design Engineer', label: 'Design Engineer', icon: '🎨' },
    { value: 'DevOps Engineer', label: 'DevOps Engineer', icon: '🔧' },
    { value: 'Frontend Engineer', label: 'Frontend Engineer', icon: '🎯' },
    { value: 'Backend Engineer', label: 'Backend Engineer', icon: '⚙️' },
    { value: 'Full Stack Engineer', label: 'Full Stack Engineer', icon: '🔄' },
    { value: 'ML Engineer', label: 'ML Engineer', icon: '🤖' },
    { value: 'Custom', label: 'Custom Role', icon: '✨' }
];

const LANGUAGE_OPTIONS = [
    { value: 'English', label: 'English', flag: '🇺🇸' },
    { value: 'Spanish', label: 'Spanish', flag: '🇪🇸' },
    { value: 'French', label: 'French', flag: '🇫🇷' },
    { value: 'German', label: 'German', flag: '🇩🇪' },
    { value: 'Chinese', label: 'Chinese', flag: '🇨🇳' },
    { value: 'Japanese', label: 'Japanese', flag: '🇯🇵' },
    { value: 'Korean', label: 'Korean', flag: '🇰🇷' },
    { value: 'Hindi', label: 'Hindi', flag: '🇮🇳' },
    { value: 'Portuguese', label: 'Portuguese', flag: '🇵🇹' },
    { value: 'Russian', label: 'Russian', flag: '🇷🇺' }
];

// Helper Components
const StepIndicator = ({ currentStep, totalSteps = 3 }) => (
    <div className="flex items-center justify-center gap-3 mb-6">
        {Array.from({ length: totalSteps }, (_, i) => (
            <motion.div
                key={i + 1}
                initial={false}
                animate={{
                    scale: currentStep === i + 1 ? 1.2 : 1,
                    backgroundColor: currentStep === i + 1 ? '#F97316' : '#E5E7EB'
                }}
                className={`
                    w-2.5 h-2.5 rounded-full transition-colors duration-300
                    ${currentStep === i + 1 ? 'bg-orange-500' : 'bg-gray-200'}
                `}
                whileHover={{ scale: 1.3 }}
            />
        ))}
    </div>
);

StepIndicator.propTypes = {
    currentStep: PropTypes.number.isRequired,
    totalSteps: PropTypes.number
};

const ToggleButton = ({
    isActive,
    onToggle,
    activeIcon,
    inactiveIcon,
    activeLabel,
    inactiveLabel,
    disabled = false
}) => (
    <motion.button
        type="button"
        onClick={onToggle}
        disabled={disabled}
        whileHover={{ scale: disabled ? 1 : 1.02 }}
        whileTap={{ scale: disabled ? 1 : 0.98 }}
        className={`
            flex items-center gap-2 px-4 py-2 rounded-lg font-medium transition-all duration-300
            ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
            ${isActive
                ? 'bg-orange-500 text-white shadow-lg shadow-orange-500/30'
                : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
            }
        `}
    >
        {isActive ? activeIcon : inactiveIcon}
        <span>{isActive ? activeLabel : inactiveLabel}</span>
        <motion.div
            animate={{ rotate: isActive ? 180 : 0 }}
            transition={{ duration: 0.3 }}
        >
            <ArrowRight className="w-4 h-4" />
        </motion.div>
    </motion.button>
);

ToggleButton.propTypes = {
    isActive: PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    activeIcon: PropTypes.node.isRequired,
    inactiveIcon: PropTypes.node.isRequired,
    activeLabel: PropTypes.string.isRequired,
    inactiveLabel: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};

const ResumeCard = ({ resume, isSelected, onSelect }) => (
    <motion.div
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={onSelect}
        className={`
            p-4 border rounded-lg cursor-pointer transition-all
            ${isSelected
                ? 'border-orange-500 bg-orange-50 shadow-lg shadow-orange-500/20'
                : 'border-gray-200 hover:border-orange-300'
            }
        `}
    >
        <div className="flex items-start gap-3">
            <div className={`
                p-2 rounded-lg transition-colors duration-300
                ${isSelected ? 'bg-orange-500 text-white' : 'bg-gray-100 text-gray-400'}
            `}>
                <FileText className="w-5 h-5" />
            </div>
            <div className="flex-1">
                <h4 className="font-medium text-gray-900 flex items-center gap-2">
                    {resume.candidate_name || resume.file_name}
                    {isSelected && (
                        <motion.span
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            className="text-orange-500"
                        >
                            <Sparkles className="w-4 h-4" />
                        </motion.span>
                    )}
                </h4>
                <p className="text-sm text-gray-500 mt-1">
                    {resume.company && `${resume.company} - `}
                    {resume.role || 'No role specified'}
                </p>
                <p className="text-xs text-gray-400 mt-1 flex items-center gap-1">
                    <KeyRound className="w-3 h-3" />
                    Uploaded {new Date(resume.created_at).toLocaleDateString()}
                </p>
            </div>
            <AnimatePresence>
                {isSelected && (
                    <motion.div
                        initial={{ scale: 0, rotate: -180 }}
                        animate={{ scale: 1, rotate: 0 }}
                        exit={{ scale: 0, rotate: 180 }}
                        className="text-orange-500"
                    >
                        <CheckCircle2 className="w-5 h-5" />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    </motion.div>
);

ResumeCard.propTypes = {
    resume: PropTypes.shape({
        id: PropTypes.string.isRequired,
        candidate_name: PropTypes.string,
        file_name: PropTypes.string.isRequired,
        company: PropTypes.string,
        role: PropTypes.string,
        created_at: PropTypes.string.isRequired
    }).isRequired,
    isSelected: PropTypes.bool.isRequired,
    onSelect: PropTypes.func.isRequired
};

// Supabase interaction functions
const saveInterviewToSupabase = async (interviewData) => {
    try {
        const effectiveUserId = isDev
            ? (interviewData.userId || DEV_FALLBACK_UUID)
            : interviewData.userId;

        const { data, error } = await supabase
            .from('interview_sessions_prod')
            .insert([{
                session_id: interviewData.sessionId,
                user_id: effectiveUserId,
                company_name: interviewData.selectedCompany === 'Custom'
                    ? interviewData.customCompany
                    : interviewData.selectedCompany,
                job_description: interviewData.selectedRole === 'Custom'
                    ? interviewData.customRole
                    : interviewData.selectedRole,
                interview_language: interviewData.language,
                special_instructions: interviewData.specialInstructions,
                simple_english: interviewData.simpleEnglish,
                generate_summary: interviewData.generateSummary,
                resume_id: interviewData.resumeId,
                resume_text: interviewData.resumeText,
                status: 'created',
                is_trial: isDev || !interviewData.isPremium,
                session_metadata: {
                    clientTimestamp: new Date().toISOString(),
                    browserInfo: navigator.userAgent,
                    isDevelopment: isDev,
                    resumeSource: interviewData.resumeSource,
                    originalCompany: interviewData.selectedCompany,
                    originalRole: interviewData.selectedRole,
                    version: '2.0.0'
                }
            }])
            .select();

        if (error) throw error;
        return data;
    } catch (error) {
        console.error('Error saving to Supabase:', error);
        if (isDev) {
            return [{
                id: `dev_${Math.random().toString(36).substr(2, 9)}`,
                session_id: interviewData.sessionId,
                ...interviewData
            }];
        }
        throw error;
    }
};

// Main Component
const CreateInterviewModal = ({ isOpen, onClose, onComplete }) => {
    const [step, setStep] = useState(1);
    const [direction, setDirection] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoadingResumes, setIsLoadingResumes] = useState(true);
    const [resumes, setResumes] = useState([]);
    const [selectedResumeId, setSelectedResumeId] = useState(null);
    const [isUploadingResume, setIsUploadingResume] = useState(false);
    const [error, setError] = useState(null);

    const [formData, setFormData] = useState({
        selectedCompany: 'Amazon',
        customCompany: '',
        selectedRole: 'Software Engineer',
        customRole: '',
        language: 'English',
        specialInstructions: '',
        simpleEnglish: false,
        generateSummary: true,
        resumeId: null,
        resumeText: '',
        useTextInput: false,
        resumeSource: 'upload'
    });

    // Load existing resumes
    useEffect(() => {
        const fetchResumes = async () => {
            try {
                const userId = isDev ? DEV_FALLBACK_UUID : supabase.auth.user()?.id;
                const { data, error } = await supabase
                    .from('resume_uploads')
                    .select('*')
                    .eq('uploaded_by', userId)
                    .eq('parse_status', 'completed')
                    .order('created_at', { ascending: false });

                if (error) throw error;
                setResumes(data || []);
            } catch (err) {
                console.error('Error fetching resumes:', err);
                setError('Failed to load resumes');
            } finally {
                setIsLoadingResumes(false);
            }
        };

        if (isOpen) {
            void fetchResumes();
        }
    }, [isOpen]);

    const handleResumeSelect = (resume) => {
        setSelectedResumeId(resume.id);
        setFormData(prev => ({
            ...prev,
            resumeId: resume.id,
            resumeText: resume.resume_text || '',
            resumeSource: 'upload'
        }));
    };

    const handleFileUpload = async (file) => {
        if (!file) return;

        if (file.size > 10 * 1024 * 1024) {
            setError('File size must be less than 10MB');
            return;
        }

        const allowedTypes = [
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ];

        if (!allowedTypes.includes(file.type)) {
            setError('Only PDF, DOC, and DOCX files are allowed');
            return;
        }

        setError(null);
        setIsUploadingResume(true);

        try {
            const userId = isDev ? DEV_FALLBACK_UUID : supabase.auth.user()?.id;

            const apiFormData = new FormData();
            apiFormData.append('file', file);
            apiFormData.append('user_id', userId);
            apiFormData.append('parse', 'true');
            apiFormData.append('mode', 'new');
            apiFormData.append('company', formData.selectedCompany === 'Custom'
                ? formData.customCompany
                : formData.selectedCompany);
            apiFormData.append('role', formData.selectedRole === 'Custom'
                ? formData.customRole
                : formData.selectedRole);

            const response = await fetch(
                'https://robo-resumeably-backend.onrender.com/api/v1/resume/upload_robo',
                {
                    method: 'POST',
                    body: apiFormData
                }
            );

            if (!response.ok) {
                throw new Error('Failed to upload resume');
            }

            const result = await response.json();

            if (result.success && result.parse_status === 'completed') {
                // Fetch the newly created resume to get all fields
                const { data: newResume } = await supabase
                    .from('resume_uploads')
                    .select('*')
                    .eq('id', result.record_id)
                    .single();

                if (newResume) {
                    setResumes(prev => [newResume, ...prev]);
                    setSelectedResumeId(result.record_id);
                    setFormData(prev => ({
                        ...prev,
                        resumeId: result.record_id,
                        resumeText: newResume.resume_text || '',
                        resumeSource: 'upload'
                    }));
                }
            } else {
                throw new Error('Resume processing failed');
            }
        } catch (err) {
            console.error('Upload error:', err);
            setError(err.message || 'Failed to upload resume');
        } finally {
            setIsUploadingResume(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (step === 1) {
            setDirection(1);
            setStep(2);
            return;
        }
        if (step === 2) {
            setDirection(1);
            setStep(3);
            return;
        }

        // Validate resume input
        if (!formData.useTextInput && !selectedResumeId) {
            setError('Please select or upload a resume to continue');
            return;
        }

        if (formData.useTextInput && !formData.resumeText.trim()) {
            setError('Please enter resume text to continue');
            return;
        }

        setIsSubmitting(true);
        try {
            const sessionId = `session_${crypto.randomUUID()}`;
            const interviewData = {
                sessionId,
                userId: isDev ? DEV_FALLBACK_UUID : undefined,
                ...formData,
                resumeId: formData.useTextInput ? null : selectedResumeId,
                timestamp: new Date().toISOString()
            };

            const savedData = await saveInterviewToSupabase(interviewData);
            onComplete({
                ...interviewData,
                dbSession: savedData?.[0]
            });
        } catch (err) {
            console.error('Error:', err);
            setError('Error creating interview. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleStepChange = (newStep) => {
        setDirection(newStep > step ? 1 : -1);
        setStep(newStep);
    };

    const toggleInputMode = () => {
        setFormData(prev => ({
            ...prev,
            useTextInput: !prev.useTextInput,
            resumeId: null,
            resumeText: '',
            resumeSource: !prev.useTextInput ? 'text' : 'upload'
        }));
        setSelectedResumeId(null);
    };

    if (!isOpen) return null;

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-gradient-to-br from-black/50 to-black/70 backdrop-blur-sm flex items-center justify-center z-50 p-4"
            >
                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    className="bg-white rounded-xl w-full max-w-2xl overflow-hidden shadow-2xl"
                >
                    {/* Header with brand elements */}
                    <div className="flex items-center justify-between p-6 border-b bg-gradient-to-r from-orange-500 to-orange-600">
                        <div className="flex items-center gap-3">
                            <img
                                src="/favicon.ico"
                                alt="RoboResumeably"
                                className="w-8 h-8 rounded-lg shadow-lg"
                            />
                            <div>
                                <h2 className="text-xl font-semibold text-white flex items-center gap-2">
                                    Create Interview Session
                                    <Sparkles className="w-5 h-5 text-orange-200" />
                                </h2>
                                <p className="text-sm text-orange-100 mt-1">
                                    {step === 1 ? 'Interview Details'
                                        : step === 2 ? 'Customize Experience'
                                            : 'Resume Input'}
                                </p>
                            </div>
                        </div>
                        <button
                            onClick={onClose}
                            disabled={isSubmitting || isUploadingResume}
                            className="p-2 hover:bg-white/10 rounded-full transition-colors text-white disabled:opacity-50"
                            type="button"
                        >
                            <X className="w-5 h-5" />
                        </button>
                    </div>

                    <StepIndicator currentStep={step} totalSteps={3} />

                    <motion.form
                        onSubmit={handleSubmit}
                        className="p-6 space-y-4"
                        variants={stepVariants}
                        custom={direction}
                        initial="enter"
                        animate="center"
                        exit="exit"
                    >
                        {/* Step 1: Company & Role Selection */}
                        {step === 1 && (
                            <motion.div
                                variants={fadeInUp}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                className="space-y-4"
                            >
                                <div className="space-y-2">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Company
                                    </label>
                                    <select
                                        value={formData.selectedCompany}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            selectedCompany: e.target.value,
                                            customCompany: ''
                                        }))}
                                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 bg-white"
                                        disabled={isSubmitting}
                                    >
                                        {COMPANY_OPTIONS.map(company => (
                                            <option key={company.value} value={company.value}>
                                                {company.icon} {company.label}
                                            </option>
                                        ))}
                                    </select>
                                    {formData.selectedCompany === 'Custom' && (
                                        <motion.input
                                            initial={{ opacity: 0, y: -10 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -10 }}
                                            type="text"
                                            value={formData.customCompany}
                                            onChange={(e) => setFormData(prev => ({
                                                ...prev,
                                                customCompany: e.target.value
                                            }))}
                                            placeholder="Enter company name"
                                            className="mt-2 w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500"
                                        />
                                    )}
                                </div>

                                <div className="space-y-2">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Role
                                    </label>
                                    <select
                                        value={formData.selectedRole}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            selectedRole: e.target.value,
                                            customRole: ''
                                        }))}
                                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 bg-white"
                                        disabled={isSubmitting}
                                    >
                                        {ROLE_OPTIONS.map(role => (
                                            <option key={role.value} value={role.value}>
                                                {role.icon} {role.label}
                                            </option>
                                        ))}
                                    </select>
                                    {formData.selectedRole === 'Custom' && (
                                        <motion.input
                                            initial={{ opacity: 0, y: -10 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -10 }}
                                            type="text"
                                            value={formData.customRole}
                                            onChange={(e) => setFormData(prev => ({
                                                ...prev,
                                                customRole: e.target.value
                                            }))}
                                            placeholder="Enter role title"
                                            className="mt-2 w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500"
                                        />
                                    )}
                                </div>

                                <div className="space-y-2">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Interview Language
                                    </label>
                                    <select
                                        value={formData.language}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            language: e.target.value
                                        }))}
                                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 bg-white"
                                        disabled={isSubmitting}
                                    >
                                        {LANGUAGE_OPTIONS.map(lang => (
                                            <option key={lang.value} value={lang.value}>
                                                {lang.flag} {lang.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </motion.div>
                        )}

                        {/* Step 2: Interview Settings */}
                        {step === 2 && (
                            <motion.div
                                variants={fadeInUp}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                className="space-y-6"
                            >
                                <div className="space-y-2">
                                    <label className="block text-sm font-medium text-gray-700">
                                        Special Instructions
                                    </label>
                                    <textarea
                                        value={formData.specialInstructions}
                                        onChange={(e) => setFormData(prev => ({
                                            ...prev,
                                            specialInstructions: e.target.value
                                        }))}
                                        placeholder="E.g., Focus on system design, keep it technical..."
                                        className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 resize-none"
                                        rows={4}
                                        disabled={isSubmitting}
                                    />
                                </div>

                                <div className="space-y-4 bg-orange-50 p-4 rounded-lg">
                                    <h4 className="font-medium text-orange-800 flex items-center gap-2">
                                        <Sparkles className="w-5 h-5" />
                                        Interview Preferences
                                    </h4>

                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            checked={formData.simpleEnglish}
                                            onChange={(e) => setFormData(prev => ({
                                                ...prev,
                                                simpleEnglish: e.target.checked
                                            }))}
                                            className="sr-only peer"
                                            disabled={isSubmitting}
                                        />
                                        <div className="w-11 h-6 bg-gray-200 peer-focus:ring-2 peer-focus:ring-orange-500 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-500" />
                                        <div className="ml-3">
                                            <span className="text-sm font-medium text-gray-700">Use Simple English</span>
                                            <p className="text-xs text-gray-500">Make questions easier to understand</p>
                                        </div>
                                    </label>

                                    <label className="relative inline-flex items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            checked={formData.generateSummary}
                                            onChange={(e) => setFormData(prev => ({
                                                ...prev,
                                                generateSummary: e.target.checked
                                            }))}
                                            className="sr-only peer"
                                            disabled={isSubmitting}
                                        />
                                        <div className="w-11 h-6 bg-gray-200 peer-focus:ring-2 peer-focus:ring-orange-500 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-500" />
                                        <div className="ml-3">
                                            <span className="text-sm font-medium text-gray-700">Generate AI Summary</span>
                                            <p className="text-xs text-gray-500">Get an AI-powered interview summary</p>
                                        </div>
                                    </label>
                                </div>

                                {/* Preview Card */}
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    className="bg-white border border-orange-200 rounded-lg p-4 shadow-sm"
                                >
                                    <h4 className="font-medium text-orange-800 flex items-center gap-2 mb-3">
                                        <CheckCircle2 className="w-5 h-5" />
                                        Interview Configuration
                                    </h4>
                                    <div className="space-y-2 text-sm text-gray-600">
                                        <p>
                                            <span className="font-medium">Company:</span>{' '}
                                            {formData.selectedCompany === 'Custom'
                                                ? formData.customCompany
                                                : formData.selectedCompany}
                                        </p>
                                        <p>
                                            <span className="font-medium">Role:</span>{' '}
                                            {formData.selectedRole === 'Custom'
                                                ? formData.customRole
                                                : formData.selectedRole}
                                        </p>
                                        <p>
                                            <span className="font-medium">Language:</span>{' '}
                                            {formData.language}
                                        </p>
                                        {formData.specialInstructions && (
                                            <p>
                                                <span className="font-medium">Special Instructions:</span>{' '}
                                                {formData.specialInstructions}
                                            </p>
                                        )}
                                        <div className="flex gap-2 mt-2">
                                            {formData.simpleEnglish && (
                                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                                    Simple English
                                                </span>
                                            )}
                                            {formData.generateSummary && (
                                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                                    AI Summary
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </motion.div>
                            </motion.div>
                        )}

                        {/* Step 3: Resume Input */}
                        {step === 3 && (
                            <motion.div
                                variants={fadeInUp}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                className="space-y-4"
                            >
                                {error && (
                                    <motion.div
                                        initial={{ opacity: 0, y: -10 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        className="p-4 bg-red-50 rounded-lg border border-red-200"
                                    >
                                        <div className="flex items-start gap-3">
                                            <AlertCircle className="w-5 h-5 text-red-600 mt-0.5" />
                                            <p className="text-red-800">{error}</p>
                                        </div>
                                    </motion.div>
                                )}

                                <div className="flex items-center justify-between">
                                    <h3 className="text-lg font-medium flex items-center gap-2">
                                        {formData.useTextInput ? (
                                            <>
                                                <Type className="w-5 h-5 text-orange-500" />
                                                Enter Resume Text
                                            </>
                                        ) : (
                                            <>
                                                <File className="w-5 h-5 text-orange-500" />
                                                Upload Resume
                                            </>
                                        )}
                                    </h3>
                                    <div className="flex items-center gap-2">
                                        <ToggleButton
                                            isActive={formData.useTextInput}
                                            onToggle={toggleInputMode}
                                            activeIcon={<Type className="w-4 h-4" />}
                                            inactiveIcon={<File className="w-4 h-4" />}
                                            activeLabel="Using Text"
                                            inactiveLabel="Using Text"
                                            disabled={isSubmitting || isUploadingResume}
                                        />

                                        {!formData.useTextInput && (
                                            <>
                                                <motion.button
                                                    type="button"
                                                    onClick={() => document.getElementById('resume-upload').click()}
                                                    disabled={isUploadingResume || isSubmitting}
                                                    whileHover={{ scale: 1.02 }}
                                                    whileTap={{ scale: 0.98 }}
                                                    className="flex items-center gap-2 px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 shadow-lg shadow-orange-500/30 disabled:opacity-50 disabled:cursor-not-allowed"
                                                >
                                                    <Upload className="w-4 h-4" />
                                                    Upload New
                                                </motion.button>
                                                <input
                                                    id="resume-upload"
                                                    type="file"
                                                    className="hidden"
                                                    accept=".pdf,.doc,.docx"
                                                    onChange={(e) => handleFileUpload(e.target.files?.[0])}
                                                    disabled={isUploadingResume || isSubmitting}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>

                                <AnimatePresence mode="wait">
                                    {formData.useTextInput ? (
                                        <motion.div
                                            key="text-input"
                                            variants={fadeInUp}
                                            initial="initial"
                                            animate="animate"
                                            exit="exit"
                                            className="space-y-2"
                                        >
                                            <div className="relative">
                                                <textarea
                                                    value={formData.resumeText}
                                                    onChange={(e) => setFormData(prev => ({
                                                        ...prev,
                                                        resumeText: e.target.value,
                                                        resumeId: null
                                                    }))}
                                                    placeholder="Paste your resume text here..."
                                                    className="w-full h-64 px-4 py-3 border rounded-lg focus:ring-2 focus:ring-orange-500 resize-none font-mono text-sm bg-gray-50"
                                                    disabled={isSubmitting}
                                                />
                                                <div className="absolute bottom-3 right-3 text-xs text-gray-400">
                                                    {formData.resumeText.length} characters
                                                </div>
                                            </div>
                                            <motion.div
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                className="flex items-center gap-2 text-sm text-gray-500"
                                            >
                                                <BookOpen className="w-4 h-4" />
                                                <p>Enter or paste your resume content here.</p>
                                            </motion.div>
                                        </motion.div>
                                    ) : (
                                        <motion.div
                                            key="file-upload"
                                            variants={fadeInUp}
                                            initial="initial"
                                            animate="animate"
                                            exit="exit"
                                            className="space-y-4"
                                        >
                                            {isLoadingResumes ? (
                                                <div className="flex items-center justify-center py-8">
                                                    <motion.div
                                                        animate={{ rotate: 360 }}
                                                        transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                                                    >
                                                        <Loader2 className="w-8 h-8 text-orange-500" />
                                                    </motion.div>
                                                </div>
                                            ) : resumes.length === 0 ? (
                                                <div className="text-center py-8 text-gray-500">
                                                    <FileText className="w-12 h-12 mx-auto text-gray-400 mb-3" />
                                                    <p>No resumes found. Upload one to continue.</p>
                                                </div>
                                            ) : (
                                                <div className="space-y-3 max-h-64 overflow-y-auto pr-2 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                                                    {resumes.map((resume) => (
                                                        <ResumeCard
                                                            key={resume.id}
                                                            resume={resume}
                                                            isSelected={selectedResumeId === resume.id}
                                                            onSelect={() => handleResumeSelect(resume)}
                                                        />
                                                    ))}
                                                </div>
                                            )}

                                            {isUploadingResume && (
                                                <motion.div
                                                    initial={{ opacity: 0, y: 20 }}
                                                    animate={{ opacity: 1, y: 0 }}
                                                    className="p-4 bg-orange-50 rounded-lg border border-orange-200"
                                                >
                                                    <div className="flex items-center gap-3">
                                                        <motion.div
                                                            animate={{ rotate: 360 }}
                                                            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                                                        >
                                                            <Loader2 className="w-5 h-5 text-orange-500" />
                                                        </motion.div>
                                                        <span className="font-medium text-orange-800">
                                                            Processing resume...
                                                        </span>
                                                    </div>
                                                </motion.div>
                                            )}
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </motion.div>
                        )}

                        {/* Helper Text */}
                        <div className="p-4 bg-blue-50 rounded-lg border border-blue-200">
                            <div className="flex items-start gap-3">
                                <AlertCircle className="w-5 h-5 text-blue-600 mt-0.5" />
                                <div>
                                    <h4 className="font-medium text-blue-800">Important Note</h4>
                                    <p className="mt-1 text-sm text-blue-600">
                                        {step === 1
                                            ? 'Provide accurate details to help personalize your interview experience.'
                                            : step === 2
                                                ? 'You can adjust these settings during the interview if needed.'
                                                : formData.useTextInput
                                                    ? 'Your entered text will be used to personalize the interview questions.'
                                                    : 'Select an existing resume or upload a new one.'}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Action Buttons */}
                        <div className="pt-4 border-t flex justify-between">
                            <motion.button
                                type="button"
                                onClick={() => {
                                    if (step > 1) {
                                        setDirection(-1);
                                        setStep(step - 1);
                                    } else {
                                        onClose();
                                    }
                                }}
                                disabled={isSubmitting || isUploadingResume}
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                {step > 1 ? 'Back' : 'Cancel'}
                            </motion.button>

                            <motion.button
                                type="submit"
                                disabled={
                                    isSubmitting ||
                                    isUploadingResume ||
                                    (step === 3 && !formData.useTextInput && !selectedResumeId) ||
                                    (step === 3 && formData.useTextInput && !formData.resumeText.trim())
                                }
                                whileHover={{ scale: 1.02 }}
                                whileTap={{ scale: 0.98 }}
                                className="px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 
                                    transition-colors inline-flex items-center gap-2 disabled:opacity-50 
                                    disabled:cursor-not-allowed shadow-lg shadow-orange-500/30"
                            >
                                {isSubmitting ? (
                                    <>
                                        <motion.div
                                            animate={{ rotate: 360 }}
                                            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                                        >
                                            <Loader2 className="w-4 h-4" />
                                        </motion.div>
                                        <span>Processing...</span>
                                    </>
                                ) : step === 3 ? (
                                    <>
                                        <span>Start Interview</span>
                                        <Sparkles className="w-4 h-4" />
                                    </>
                                ) : (
                                    <>
                                        <span>Next</span>
                                        <ArrowRight className="w-4 h-4" />
                                    </>
                                )}
                            </motion.button>
                        </div>
                    </motion.form>
                </motion.div>
            </motion.div>
        </AnimatePresence>
    );
};

CreateInterviewModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired
};

export default CreateInterviewModal;