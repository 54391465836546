// import React, { useState, useRef, useEffect } from 'react';
// import { motion } from 'framer-motion';
// import {
//     Share2,
//     AlertCircle,
//     Maximize2
// } from 'lucide-react';

// const SessionSetupFlow = ({ onComplete, onCancel }) => {
//     const [stage, setStage] = useState('loading');
//     const [isConnecting, setIsConnecting] = useState(true);
//     const [meetingStream, setMeetingStream] = useState(null);
//     const [isPiPActive, setIsPiPActive] = useState(false);
//     const [pipError, setPipError] = useState(null);

//     const videoRef = useRef(null);

//     useEffect(() => {
//         // Check PiP support on mount
//         setPipError(!document.pictureInPictureEnabled ? 'Your browser does not support Picture-in-Picture mode.' : null);

//         // Simulate initial connection
//         setTimeout(() => {
//             setIsConnecting(false);
//             setStage('select-meeting');
//         }, 2000);

//         return () => {
//             cleanupStream();
//         };
//     }, []);

//     const cleanupStream = () => {
//         if (meetingStream) {
//             if (meetingStream.processStream) {
//                 meetingStream.processStream.getTracks().forEach(track => track.stop());
//             }
//             if (meetingStream.videoStream) {
//                 meetingStream.videoStream.getTracks().forEach(track => track.stop());
//             }
//             setMeetingStream(null);
//         }
//     };

//     const handleMeetingSourceSelect = async (sourceType) => {
//         try {
//             // Clear any existing errors
//             setPipError(null);

//             // Cleanup existing stream
//             cleanupStream();

//             console.log('Requesting display media...');
//             const displayMedia = await navigator.mediaDevices.getDisplayMedia({
//                 video: {
//                     width: { ideal: 1920 },
//                     height: { ideal: 1080 },
//                     frameRate: { ideal: 30 }
//                 },
//                 audio: {
//                     suppressLocalAudioPlayback: false,
//                     echoCancellation: true,
//                     noiseSuppression: true,
//                     autoGainControl: true
//                 }
//             });

//             const videoTrack = displayMedia.getVideoTracks()[0];
//             const audioTrack = displayMedia.getAudioTracks()[0];

//             // Create a new MediaStream with cloned tracks for our AI processing
//             const processStream = new MediaStream([
//                 videoTrack.clone(),
//                 audioTrack.clone()
//             ]);

//             // Create a new MediaStream for the video element
//             const videoStream = new MediaStream([
//                 videoTrack,
//                 audioTrack
//             ]);

//             console.log('Setting up streams...');
//             setMeetingStream({
//                 processStream,  // For AI processing
//                 videoStream    // For video display and PiP
//             });

//             console.log('Streams initialized:', {
//                 hasProcessStream: !!processStream,
//                 hasVideoStream: !!videoStream,
//                 videoTracks: videoStream.getVideoTracks().length,
//                 audioTracks: videoStream.getAudioTracks().length
//             });

//             if (videoRef.current) {
//                 videoRef.current.srcObject = videoStream;
//                 videoRef.current.muted = false;
//                 await videoRef.current.play();
//             }

//             const handleTrackEnd = () => {
//                 cleanupStream();
//                 setStage('select-meeting');
//                 setIsPiPActive(false);
//             };

//             videoTrack.onended = handleTrackEnd;
//             audioTrack.onended = handleTrackEnd;

//             setStage('ready');
//         } catch (error) {
//             console.error('Meeting source selection error:', error);
//             if (error.name === 'NotAllowedError') {
//                 alert('Please select your meeting tab/window to continue.');
//             } else {
//                 alert('Failed to access screen sharing. Please try again.');
//             }
//         }
//     };

//     const togglePiP = async () => {
//         try {
//             // First check if we're exiting PiP
//             if (document.pictureInPictureElement) {
//                 await document.exitPictureInPicture();
//                 setIsPiPActive(false);
//                 return;
//             }

//             // Validate stream existence
//             if (!meetingStream?.videoStream) {
//                 setPipError('Please share your meeting tab first before enabling Picture-in-Picture.');
//                 return;
//             }

//             // Validate video element
//             if (!videoRef.current) {
//                 setPipError('Video element not initialized. Please try refreshing the page.');
//                 return;
//             }

//             // Ensure we have an active stream
//             if (!videoRef.current.srcObject) {
//                 videoRef.current.srcObject = meetingStream.videoStream;
//             }

//             // Ensure video is playing
//             if (videoRef.current.paused) {
//                 try {
//                     await videoRef.current.play();
//                 } catch (playError) {
//                     console.error('Error playing video:', playError);
//                     setPipError('Failed to play video stream. Please ensure you have granted all necessary permissions.');
//                     return;
//                 }
//             }

//             const pipWindow = await videoRef.current.requestPictureInPicture();

//             if (pipWindow.width && pipWindow.height) {
//                 try {
//                     await pipWindow.setWidth(480);
//                     await pipWindow.setHeight(270);
//                 } catch (e) {
//                     console.warn('Failed to set PiP size:', e);
//                 }
//             }

//             setIsPiPActive(true);
//         } catch (error) {
//             console.error('PiP error:', error);
//             setPipError(error.message || 'Failed to enable Picture-in-Picture. Please try again.');
//         }
//     };

//     // Loading Screen
//     if (stage === 'loading') {
//         return (
//             <div className="fixed inset-0 bg-white flex flex-col items-center justify-center">
//                 <motion.div
//                     animate={{ rotate: 360 }}
//                     transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
//                     className="w-12 h-12 border-4 border-orange-500 border-t-transparent rounded-full"
//                 />
//                 <h2 className="mt-4 text-xl font-semibold">Session loading...</h2>
//                 <p className="mt-2 text-gray-500">
//                     We are <span className="text-orange-500 font-medium">connecting to copilot server</span>
//                 </p>
//             </div>
//         );
//     }

//     return (
//         <div className="min-h-screen bg-gray-50 p-8">
//             <div className="max-w-xl mx-auto">
//                 <div className="bg-white rounded-lg shadow-sm p-6">
//                     {/* Header */}
//                     <div className="flex items-center justify-between mb-6">
//                         <h1 className="text-2xl font-semibold">Live Interview Setup</h1>
//                         <div className="flex items-center gap-2">
//                             <span className="px-3 py-1 bg-orange-100 text-orange-600 rounded-full text-sm font-medium">
//                                 Premium
//                             </span>
//                         </div>
//                     </div>

//                     <div className="space-y-6">
//                         {/* Meeting Source Selection */}
//                         {stage === 'select-meeting' && (
//                             <div className="text-center py-4">
//                                 <h2 className="text-xl font-semibold mb-4">
//                                     Select Meeting Source
//                                 </h2>
//                                 <div className="flex flex-col items-center gap-6">
//                                     <div className="flex justify-center gap-4">
//                                         <button
//                                             onClick={() => handleMeetingSourceSelect('tab')}
//                                             className="flex items-center gap-2 px-4 py-2 rounded-lg border hover:bg-gray-50 transition-colors"
//                                         >
//                                             <Share2 className="w-5 h-5" />
//                                             Share Meeting Tab
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         )}

//                         {/* PiP Setup */}
//                         {stage === 'ready' && (
//                             <div className="space-y-4">
//                                 <div className="relative">
//                                     <video
//                                         ref={videoRef}
//                                         autoPlay
//                                         playsInline
//                                         controls
//                                         className="w-full rounded-lg bg-black aspect-video"
//                                     />
//                                     {!pipError && (
//                                         <motion.button
//                                             initial={{ scale: 1 }}
//                                             animate={{ scale: [1, 1.05, 1] }}
//                                             transition={{ duration: 2, repeat: Infinity }}
//                                             onClick={togglePiP}
//                                             className="absolute bottom-4 right-4 inline-flex items-center gap-2 px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 shadow-lg"
//                                         >
//                                             <Maximize2 className="w-5 h-5" />
//                                             {isPiPActive ? 'Exit Picture-in-Picture' : 'Enable Picture-in-Picture'}
//                                         </motion.button>
//                                     )}
//                                 </div>

//                                 {pipError && (
//                                     <div className="bg-red-50 p-4 rounded-lg">
//                                         <div className="flex items-start gap-3">
//                                             <AlertCircle className="w-5 h-5 text-red-600 mt-0.5" />
//                                             <div>
//                                                 <h4 className="font-medium text-red-800">Picture-in-Picture Error</h4>
//                                                 <p className="mt-1 text-sm text-red-600">{pipError}</p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 )}

//                                 {!isPiPActive && !pipError && (
//                                     <div className="bg-blue-50 p-4 rounded-lg">
//                                         <div className="flex items-start gap-3">
//                                             <AlertCircle className="w-5 h-5 text-blue-600 mt-0.5" />
//                                             <div>
//                                                 <h4 className="font-medium text-blue-800">Enable Picture-in-Picture</h4>
//                                                 <p className="mt-1 text-sm text-blue-600">
//                                                     Click the orange button above to keep your meeting visible while you use our interview platform.
//                                                 </p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 )}

//                                 <div className="flex justify-end gap-3 mt-6">
//                                     <button
//                                         onClick={onCancel}
//                                         className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
//                                     >
//                                         Cancel
//                                     </button>
//                                     <button
//                                         onClick={() => onComplete({
//                                             stream: meetingStream.processStream,
//                                             displayStream: meetingStream.videoStream,
//                                             videoRef,
//                                             togglePiP
//                                         })}
//                                         className="px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
//                                     >
//                                         Start Interview
//                                     </button>
//                                 </div>
//                             </div>
//                         )}

//                         {/* Helper Text */}
//                         <div className="mt-6 p-4 bg-blue-50 rounded-lg">
//                             <div className="flex items-start gap-3">
//                                 <AlertCircle className="w-5 h-5 text-blue-600 mt-0.5" />
//                                 <div>
//                                     <h4 className="font-medium text-blue-800">Important Note</h4>
//                                     <p className="mt-1 text-sm text-blue-600">
//                                         {stage === 'select-meeting'
//                                             ? 'Select your meeting tab from Google Meet, Zoom, or Microsoft Teams to continue.'
//                                             : 'Use Picture-in-Picture mode to keep your meeting visible while using our platform.'}
//                                     </p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// // export default SessionSetupFlow;

// import React, { useState, useRef, useEffect } from 'react';
// import { motion } from 'framer-motion';
// import {
//     Share2,
//     AlertCircle,
//     Maximize2
// } from 'lucide-react';

// const SessionSetupFlow = ({ onComplete, onCancel }) => {
//     const [stage, setStage] = useState('loading');
//     const [isConnecting, setIsConnecting] = useState(true);
//     const [meetingStream, setMeetingStream] = useState(null);
//     const [isPiPActive, setIsPiPActive] = useState(false);
//     const [pipError, setPipError] = useState(null);

//     const videoRef = useRef(null);

//     useEffect(() => {
//         // Check PiP support on mount
//         setPipError(!document.pictureInPictureEnabled ? 'Your browser does not support Picture-in-Picture mode.' : null);

//         // Simulate initial connection
//         setTimeout(() => {
//             setIsConnecting(false);
//             setStage('select-meeting');
//         }, 2000);

//         return () => {
//             cleanupStream();
//         };
//     }, []);

//     const cleanupStream = () => {
//         if (meetingStream) {
//             if (meetingStream.processStream) {
//                 meetingStream.processStream.getTracks().forEach(track => track.stop());
//             }
//             if (meetingStream.videoStream) {
//                 meetingStream.videoStream.getTracks().forEach(track => track.stop());
//             }
//             setMeetingStream(null);
//         }
//     };

//     const handleMeetingSourceSelect = async (sourceType) => {
//         try {
//             // Clear any existing errors
//             setPipError(null);

//             // Cleanup existing stream
//             cleanupStream();

//             console.log('Requesting display media...');
//             const displayMedia = await navigator.mediaDevices.getDisplayMedia({
//                 video: {
//                     width: { ideal: 1920 },
//                     height: { ideal: 1080 },
//                     frameRate: { ideal: 30 }
//                 },
//                 audio: {
//                     suppressLocalAudioPlayback: false,
//                     echoCancellation: true,
//                     noiseSuppression: true,
//                     autoGainControl: true
//                 }
//             });

//             const videoTrack = displayMedia.getVideoTracks()[0];
//             const audioTrack = displayMedia.getAudioTracks()[0];

//             // Create a new MediaStream with cloned tracks for our AI processing
//             const processStream = new MediaStream([
//                 videoTrack.clone(),
//                 audioTrack.clone()
//             ]);

//             // Create a new MediaStream for the video element
//             const videoStream = new MediaStream([
//                 videoTrack,
//                 audioTrack
//             ]);

//             console.log('Setting up streams...');
//             setMeetingStream({
//                 processStream,  // For AI processing
//                 videoStream    // For video display and PiP
//             });

//             console.log('Streams initialized:', {
//                 hasProcessStream: !!processStream,
//                 hasVideoStream: !!videoStream,
//                 videoTracks: videoStream.getVideoTracks().length,
//                 audioTracks: videoStream.getAudioTracks().length
//             });

//             if (videoRef.current) {
//                 videoRef.current.srcObject = videoStream;
//                 videoRef.current.muted = false;
//                 await videoRef.current.play();
//             }

//             const handleTrackEnd = () => {
//                 cleanupStream();
//                 setStage('select-meeting');
//                 setIsPiPActive(false);
//             };

//             videoTrack.onended = handleTrackEnd;
//             audioTrack.onended = handleTrackEnd;

//             setStage('ready');
//         } catch (error) {
//             console.error('Meeting source selection error:', error);
//             if (error.name === 'NotAllowedError') {
//                 alert('Please select your meeting tab/window to continue.');
//             } else {
//                 alert('Failed to access screen sharing. Please try again.');
//             }
//         }
//     };

//     const togglePiP = async () => {
//         try {
//             // First check if we're exiting PiP
//             if (document.pictureInPictureElement) {
//                 await document.exitPictureInPicture();
//                 setIsPiPActive(false);
//                 return;
//             }

//             // Validate stream existence
//             if (!meetingStream?.videoStream) {
//                 setPipError('Please share your meeting tab first before enabling Picture-in-Picture.');
//                 return;
//             }

//             // Validate video element
//             if (!videoRef.current) {
//                 setPipError('Video element not initialized. Please try refreshing the page.');
//                 return;
//             }

//             // Ensure we have an active stream
//             if (!videoRef.current.srcObject) {
//                 videoRef.current.srcObject = meetingStream.videoStream;
//             }

//             // Ensure video is playing
//             if (videoRef.current.paused) {
//                 try {
//                     await videoRef.current.play();
//                 } catch (playError) {
//                     console.error('Error playing video:', playError);
//                     setPipError('Failed to play video stream. Please ensure you have granted all necessary permissions.');
//                     return;
//                 }
//             }

//             const pipWindow = await videoRef.current.requestPictureInPicture();

//             if (pipWindow.width && pipWindow.height) {
//                 try {
//                     await pipWindow.setWidth(480);
//                     await pipWindow.setHeight(270);
//                 } catch (e) {
//                     console.warn('Failed to set PiP size:', e);
//                 }
//             }

//             setIsPiPActive(true);
//         } catch (error) {
//             console.error('PiP error:', error);
//             setPipError(error.message || 'Failed to enable Picture-in-Picture. Please try again.');
//         }
//     };

//     // Loading Screen
//     if (stage === 'loading') {
//         return (
//             <div className="fixed inset-0 bg-white flex flex-col items-center justify-center">
//                 <motion.div
//                     animate={{ rotate: 360 }}
//                     transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
//                     className="w-12 h-12 border-4 border-orange-500 border-t-transparent rounded-full"
//                 />
//                 <h2 className="mt-4 text-xl font-semibold">Session loading...</h2>
//                 <p className="mt-2 text-gray-500">
//                     We are <span className="text-orange-500 font-medium">connecting to copilot server</span>
//                 </p>
//             </div>
//         );
//     }

//     return (
//         <div className="min-h-screen bg-white flex flex-col">
//             {/* Header */}
//             <div className="border-b">
//                 <div className="flex items-center justify-between px-4 py-2">
//                     <div className="flex items-center gap-2">
//                         <h1 className="text-lg font-medium">Live Interview</h1>
//                         <span className="px-2 py-0.5 bg-orange-100 text-orange-600 rounded-full text-sm font-medium">
//                             Premium
//                         </span>
//                     </div>
//                     <div className="text-sm text-gray-500">
//                         00:00
//                     </div>
//                 </div>
//             </div>

//             <div className="flex-1 p-4">
//                 <div className="max-w-4xl mx-auto">
//                     {/* Meeting Source Selection */}
//                     {stage === 'select-meeting' && (
//                         <div className="bg-black rounded-lg aspect-video flex flex-col items-center justify-center text-white p-8">
//                             <h2 className="text-xl font-medium mb-6">
//                                 Connect to your interview meeting room
//                             </h2>
//                             <button
//                                 onClick={() => handleMeetingSourceSelect('tab')}
//                                 className="flex items-center gap-2 px-6 py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
//                             >
//                                 <Share2 className="w-5 h-5" />
//                                 Select
//                             </button>
//                             <p className="mt-6 text-sm text-gray-400 text-center">
//                                 Once you have selected the interview meeting room<br />
//                                 the transcript will be displayed here.
//                             </p>
//                         </div>
//                     )}

//                     {/* PiP Setup */}
//                     {stage === 'ready' && (
//                         <div>
//                             <div className="relative bg-black rounded-lg overflow-hidden">
//                                 <video
//                                     ref={videoRef}
//                                     autoPlay
//                                     playsInline
//                                     controls
//                                     className="w-full aspect-video"
//                                 />
//                                 {!pipError && (
//                                     <button
//                                         onClick={togglePiP}
//                                         className="absolute bottom-4 right-4 inline-flex items-center gap-2 px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 shadow-lg"
//                                     >
//                                         <Maximize2 className="w-5 h-5" />
//                                         {isPiPActive ? 'Exit Picture-in-Picture' : 'Enable Picture-in-Picture'}
//                                     </button>
//                                 )}
//                             </div>

//                             {/* Errors and Status */}
//                             <div className="mt-4 space-y-4">
//                                 {pipError && (
//                                     <div className="bg-red-50 border border-red-100 rounded-lg p-4">
//                                         <div className="flex items-start gap-3">
//                                             <AlertCircle className="w-5 h-5 text-red-600 mt-0.5 flex-shrink-0" />
//                                             <div>
//                                                 <h4 className="font-medium text-red-800">Picture-in-Picture Error</h4>
//                                                 <p className="mt-1 text-sm text-red-600">{pipError}</p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 )}

//                                 <div className="flex items-center justify-between">
//                                     <div className="flex items-center gap-4">
//                                         <div className="flex items-center gap-2">
//                                             <span className="inline-block w-2 h-2 bg-green-500 rounded-full"></span>
//                                             <span className="text-sm text-gray-600">Ready</span>
//                                         </div>
//                                         <div className="flex items-center gap-2">
//                                             <span className="text-sm text-gray-600">AI Responses:</span>
//                                             <span className="inline-block w-2 h-2 bg-green-500 rounded-full"></span>
//                                             <span className="text-sm text-gray-600">Ready</span>
//                                         </div>
//                                     </div>

//                                     {/* Controls moved to right side */}
//                                     <div className="flex items-center gap-3">
//                                         <button
//                                             onClick={onCancel}
//                                             className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
//                                         >
//                                             Cancel
//                                         </button>
//                                         <button
//                                             onClick={() => onComplete({
//                                                 stream: meetingStream.processStream,
//                                                 displayStream: meetingStream.videoStream,
//                                                 videoRef,
//                                                 togglePiP
//                                             })}
//                                             className="px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
//                                         >
//                                             Start Interview
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {/* Helper Text */}
//                     <div className="mt-6 p-4 bg-blue-50 rounded-lg">
//                         <div className="flex items-start gap-3">
//                             <AlertCircle className="w-5 h-5 text-blue-600 mt-0.5" />
//                             <div>
//                                 <h4 className="font-medium text-blue-800">Important Note</h4>
//                                 <p className="mt-1 text-sm text-blue-600">
//                                     {stage === 'select-meeting'
//                                         ? 'Select your meeting tab from Google Meet, Zoom, or Microsoft Teams to continue.'
//                                         : 'Use Picture-in-Picture mode to keep your meeting visible while using our platform.'}
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default SessionSetupFlow;

// import React, { useState, useRef, useEffect } from 'react';
// import { motion } from 'framer-motion';
// import { Share2, AlertCircle } from 'lucide-react';

// const SessionSetupFlow = ({ onComplete, onCancel }) => {
//     const [stage, setStage] = useState('loading');
//     const [isConnecting, setIsConnecting] = useState(true);
//     const [meetingStream, setMeetingStream] = useState(null);
//     const [pipError, setPipError] = useState(null);

//     const videoRef = useRef(null);

//     useEffect(() => {
//         // Check PiP support on mount
//         setPipError(!document.pictureInPictureEnabled ? 'Your browser does not support Picture-in-Picture mode.' : null);

//         // Simulate initial connection
//         setTimeout(() => {
//             setIsConnecting(false);
//             setStage('select-meeting');
//         }, 2000);

//         return () => {
//             cleanupStream();
//         };
//     }, []);

//     const cleanupStream = () => {
//         if (meetingStream) {
//             if (meetingStream.processStream) {
//                 meetingStream.processStream.getTracks().forEach(track => track.stop());
//             }
//             if (meetingStream.videoStream) {
//                 meetingStream.videoStream.getTracks().forEach(track => track.stop());
//             }
//             setMeetingStream(null);
//         }
//     };

//     const handleMeetingSourceSelect = async (sourceType) => {
//         try {
//             // Clear any existing errors
//             setPipError(null);

//             // Cleanup existing stream
//             cleanupStream();

//             console.log('Requesting display media...');
//             const displayMedia = await navigator.mediaDevices.getDisplayMedia({
//                 video: {
//                     width: { ideal: 1920 },
//                     height: { ideal: 1080 },
//                     frameRate: { ideal: 30 }
//                 },
//                 audio: {
//                     suppressLocalAudioPlayback: false,
//                     echoCancellation: true,
//                     noiseSuppression: true,
//                     autoGainControl: true
//                 }
//             });

//             // Verify audio tracks
//             const audioTracks = displayMedia.getAudioTracks();
//             if (audioTracks.length === 0) {
//                 console.warn('⚠️ No audio tracks found in the display media stream');
//                 alert('No audio detected. Please ensure you have selected a tab/window with audio.');
//                 return;
//             }

//             const videoTrack = displayMedia.getVideoTracks()[0];
//             const audioTrack = audioTracks[0];

//             // Create a new MediaStream with cloned tracks for our AI processing
//             const processStream = new MediaStream([
//                 videoTrack.clone(),
//                 audioTrack.clone()
//             ]);

//             // Create a new MediaStream for the video element
//             const videoStream = new MediaStream([
//                 videoTrack,
//                 audioTrack
//             ]);

//             console.log('Setting up streams...');
//             setMeetingStream({
//                 processStream,  // For AI processing
//                 videoStream    // For video display and PiP
//             });

//             console.log('Streams initialized:', {
//                 hasProcessStream: !!processStream,
//                 hasVideoStream: !!videoStream,
//                 videoTracks: videoStream.getVideoTracks().length,
//                 audioTracks: videoStream.getAudioTracks().length
//             });

//             if (videoRef.current) {
//                 videoRef.current.srcObject = videoStream;
//                 videoRef.current.muted = false;
//                 await videoRef.current.play();

//                 // Automatically enter PiP mode and center it
//                 try {
//                     if (document.pictureInPictureEnabled) {
//                         const pipWindow = await videoRef.current.requestPictureInPicture();
//                         // Center the PiP window
//                         if (pipWindow) {
//                             const screenWidth = window.screen.width;
//                             const screenHeight = window.screen.height;
//                             const pipWidth = 400; // Default PiP width
//                             const pipHeight = 225; // Default PiP height (16:9 ratio)

//                             // Set PiP window to center of screen
//                             if (pipWindow.windowId) {
//                                 await document.documentElement.requestFullscreen();
//                                 pipWindow.addEventListener('resize', () => {
//                                     const x = (screenWidth - pipWidth) / 2;
//                                     const y = (screenHeight - pipHeight) / 2;
//                                     pipWindow.style.transform = `translate(${x}px, ${y}px)`;
//                                 });
//                             }
//                         }
//                     }
//                 } catch (pipError) {
//                     console.warn('PiP initialization error:', pipError);
//                     // Continue even if PiP fails
//                 }
//             }

//             const handleTrackEnd = () => {
//                 cleanupStream();
//                 setStage('select-meeting');
//             };

//             videoTrack.onended = handleTrackEnd;
//             audioTrack.onended = handleTrackEnd;

//             setStage('ready');
//         } catch (error) {
//             console.error('Meeting source selection error:', error);
//             if (error.name === 'NotAllowedError') {
//                 alert('Please select your meeting tab/window to continue.');
//             } else if (error.name === 'NotFoundError') {
//                 alert('No audio or video source found. Please ensure your screen has audio.');
//             } else {
//                 alert('Failed to access screen sharing. Please try again.');
//             }
//         }
//     };

//     // Loading Screen
//     if (stage === 'loading') {
//         return (
//             <div className="fixed inset-0 bg-white flex flex-col items-center justify-center">
//                 <motion.div
//                     animate={{ rotate: 360 }}
//                     transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
//                     className="w-12 h-12 border-4 border-orange-500 border-t-transparent rounded-full"
//                 />
//                 <h2 className="mt-4 text-xl font-semibold">Session loading...</h2>
//                 <p className="mt-2 text-gray-500">
//                     We are <span className="text-orange-500 font-medium">connecting to copilot server</span>
//                 </p>
//             </div>
//         );
//     }

//     return (
//         <div className="min-h-screen bg-white flex flex-col">
//             {/* Header */}
//             <div className="border-b">
//                 <div className="flex items-center justify-between px-4 py-2">
//                     <div className="flex items-center gap-2">
//                         <h1 className="text-lg font-medium">Live Interview</h1>
//                         <span className="px-2 py-0.5 bg-orange-100 text-orange-600 rounded-full text-sm font-medium">
//                             Premium
//                         </span>
//                     </div>
//                     <div className="text-sm text-gray-500">
//                         00:00
//                     </div>
//                 </div>
//             </div>

//             <div className="flex-1 p-4">
//                 <div className="max-w-4xl mx-auto">
//                     {/* Meeting Source Selection */}
//                     {stage === 'select-meeting' && (
//                         <div className="bg-black rounded-lg aspect-video flex flex-col items-center justify-center text-white p-8">
//                             <h2 className="text-xl font-medium mb-6">
//                                 Connect to your interview meeting room
//                             </h2>
//                             <button
//                                 onClick={() => handleMeetingSourceSelect('tab')}
//                                 className="flex items-center gap-2 px-6 py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
//                             >
//                                 <Share2 className="w-5 h-5" />
//                                 Select
//                             </button>
//                             <p className="mt-6 text-sm text-gray-400 text-center">
//                                 Once you have selected the interview meeting room<br />
//                                 the transcript will be displayed here.
//                             </p>
//                         </div>
//                     )}

//                     {/* Video Preview */}
//                     {stage === 'ready' && (
//                         <div>
//                             <div className="relative bg-black rounded-lg overflow-hidden">
//                                 <video
//                                     ref={videoRef}
//                                     autoPlay
//                                     playsInline
//                                     className="w-full aspect-video"
//                                 />
//                             </div>

//                             {/* Status and Controls */}
//                             <div className="mt-4">
//                                 <div className="flex items-center justify-between">
//                                     <div className="flex items-center gap-4">
//                                         <div className="flex items-center gap-2">
//                                             <span className="inline-block w-2 h-2 bg-green-500 rounded-full"></span>
//                                             <span className="text-sm text-gray-600">Ready</span>
//                                         </div>
//                                         <div className="flex items-center gap-2">
//                                             <span className="text-sm text-gray-600">AI Responses:</span>
//                                             <span className="inline-block w-2 h-2 bg-green-500 rounded-full"></span>
//                                             <span className="text-sm text-gray-600">Ready</span>
//                                         </div>
//                                     </div>

//                                     <div className="flex items-center gap-3">
//                                         <button
//                                             onClick={onCancel}
//                                             className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
//                                         >
//                                             Cancel
//                                         </button>
//                                         <button
//                                             onClick={() => onComplete({
//                                                 stream: meetingStream.processStream,
//                                                 displayStream: meetingStream.videoStream,
//                                                 videoRef
//                                             })}
//                                             className="px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
//                                         >
//                                             Start Interview
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {/* Helper Text */}
//                     <div className="mt-6 p-4 bg-blue-50 rounded-lg">
//                         <div className="flex items-start gap-3">
//                             <AlertCircle className="w-5 h-5 text-blue-600 mt-0.5" />
//                             <div>
//                                 <h4 className="font-medium text-blue-800">Important Note</h4>
//                                 <p className="mt-1 text-sm text-blue-600">
//                                     {stage === 'select-meeting'
//                                         ? 'Select your meeting tab from Google Meet, Zoom, or Microsoft Teams to continue.'
//                                         : 'Your meeting window will automatically appear in Picture-in-Picture mode.'}
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default SessionSetupFlow;


// import React, { useState, useRef, useEffect } from 'react';
// import { motion } from 'framer-motion';
// import { supabase } from '../../supabaseConfig';
// import {
//     Share2,
//     AlertCircle,
//     Building,
//     Sparkles,
//     X,
//     CheckCircle2
// } from 'lucide-react';

// // Helper Functions for Session Management
// const generateId = () => 'id_' + Math.random().toString(36).substr(2, 9);

// const saveSessionToSupabase = async (sessionData) => {
//     try {
//         const { data, error } = await supabase
//             .from('interview_sessions')
//             .insert([{
//                 session_id: sessionData.sessionId,
//                 user_id: sessionData.userId,
//                 company_name: sessionData.settings.company,
//                 job_description: sessionData.settings.jobDescription,
//                 interview_language: sessionData.settings.language,
//                 special_instructions: sessionData.settings.specialInstructions,
//                 simple_english: sessionData.settings.simpleEnglish,
//                 generate_summary: sessionData.settings.generateSummary,
//                 has_audio: sessionData.meetingInfo.hasAudio,
//                 has_video: sessionData.meetingInfo.hasVideo,
//                 status: sessionData.status,
//                 is_trial: !sessionData.settings.isPremium, // Set trial status
//                 session_metadata: {
//                     // Additional metadata can be stored here
//                     clientTimestamp: new Date().toISOString(),
//                     browserInfo: navigator.userAgent
//                 }
//             }])
//             .select();

//         if (error) throw error;
//         return data;
//     } catch (error) {
//         console.error('Error saving session to Supabase:', error);
//         // Fallback to localStorage if Supabase fails
//         saveToLocalStorage(sessionData);
//     }
// };

// const saveToLocalStorage = (sessionData) => {
//     try {
//         const existingSessions = JSON.parse(localStorage.getItem('interviewSessions') || '[]');
//         existingSessions.push(sessionData);
//         localStorage.setItem('interviewSessions', JSON.stringify(existingSessions));
//         localStorage.setItem('currentSessionId', sessionData.sessionId);
//     } catch (error) {
//         console.error('Error saving to localStorage:', error);
//     }
// };

// const StepIndicator = ({ currentStep, totalSteps = 3 }) => (
//     <div className="flex items-center justify-center gap-2 mb-6">
//         {Array.from({ length: totalSteps }, (_, i) => (
//             <div
//                 key={i}
//                 className={`w-2.5 h-2.5 rounded-full ${i + 1 === currentStep ? 'bg-orange-500' : 'bg-gray-200'
//                     }`}
//             />
//         ))}
//     </div>
// );

// // Step 1: Interview Details
// const InterviewDetailsStep = ({ onNext, formData, setFormData }) => (
//     <div className="space-y-6">
//         <div className="p-6 border-b border-gray-200">
//             <div className="flex items-center space-x-2">
//                 <Building className="w-6 h-6 text-orange-500" />
//                 <h2 className="text-xl font-semibold text-gray-900">Setup Interview</h2>
//             </div>
//             <p className="mt-2 text-gray-500">Tell us about the position you're interviewing for</p>
//         </div>

//         <div className="px-6 space-y-4">
//             <div className="space-y-2">
//                 <label className="block text-sm font-medium">Company Name</label>
//                 <div className="relative">
//                     <Building className="absolute left-3 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
//                     <input
//                         type="text"
//                         value={formData.company}
//                         onChange={(e) => setFormData(prev => ({
//                             ...prev,
//                             company: e.target.value
//                         }))}
//                         className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
//                         placeholder="Enter company name"
//                         required
//                     />
//                 </div>
//             </div>

//             <div className="space-y-2">
//                 <label className="block text-sm font-medium">Job Description</label>
//                 <textarea
//                     value={formData.jobDescription}
//                     onChange={(e) => setFormData(prev => ({
//                         ...prev,
//                         jobDescription: e.target.value
//                     }))}
//                     className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
//                     placeholder="Software Engineer with Python, SQL, AWS..."
//                     rows={3}
//                     required
//                 />
//             </div>

//             <div className="space-y-2">
//                 <label className="block text-sm font-medium">Interview Language</label>
//                 <select
//                     value={formData.language}
//                     onChange={(e) => setFormData(prev => ({
//                         ...prev,
//                         language: e.target.value
//                     }))}
//                     className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500 bg-white"
//                 >
//                     <option value="English">English</option>
//                     <option value="Spanish">Spanish</option>
//                     <option value="French">French</option>
//                     <option value="German">German</option>
//                     <option value="Chinese">Chinese</option>
//                 </select>
//             </div>
//         </div>

//         <div className="px-6 mt-4">
//             <button
//                 onClick={onNext}
//                 className="w-full px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 
//                     transition-colors font-medium"
//             >
//                 Continue
//             </button>
//         </div>
//     </div>
// );

// // Step 2: AI Settings
// const AiSettingsStep = ({ onNext, onBack, formData, setFormData }) => (
//     <div className="space-y-6">
//         <div className="p-6 border-b border-gray-200">
//             <div className="flex items-center space-x-2">
//                 <Sparkles className="w-6 h-6 text-orange-500" />
//                 <h2 className="text-xl font-semibold text-gray-900">Interview Preferences</h2>
//             </div>
//             <p className="mt-2 text-gray-500">Customize your AI interview experience</p>
//         </div>

//         <div className="px-6 space-y-4">
//             <div className="space-y-2">
//                 <label className="block text-sm font-medium">Special Instructions</label>
//                 <textarea
//                     value={formData.specialInstructions}
//                     onChange={(e) => setFormData(prev => ({
//                         ...prev,
//                         specialInstructions: e.target.value
//                     }))}
//                     className="w-full px-4 py-2 border rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-orange-500"
//                     placeholder="E.g., Be more technical, focus on system design, use simpler language..."
//                     rows={3}
//                 />
//             </div>

//             <div className="space-y-4">
//                 <label className="relative inline-flex items-center cursor-pointer">
//                     <input
//                         type="checkbox"
//                         checked={formData.simpleEnglish}
//                         onChange={(e) => setFormData(prev => ({
//                             ...prev,
//                             simpleEnglish: e.target.checked
//                         }))}
//                         className="sr-only peer"
//                     />
//                     <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 
//                         peer-focus:ring-orange-500 rounded-full peer peer-checked:after:translate-x-full 
//                         peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] 
//                         after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full 
//                         after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-500"></div>
//                     <span className="ml-3 text-sm font-medium text-gray-700">Use Simple English</span>
//                 </label>

//                 <label className="relative inline-flex items-center cursor-pointer">
//                     <input
//                         type="checkbox"
//                         checked={formData.generateSummary}
//                         onChange={(e) => setFormData(prev => ({
//                             ...prev,
//                             generateSummary: e.target.checked
//                         }))}
//                         className="sr-only peer"
//                     />
//                     <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 
//                         peer-focus:ring-orange-500 rounded-full peer peer-checked:after:translate-x-full 
//                         peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] 
//                         after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full 
//                         after:h-5 after:w-5 after:transition-all peer-checked:bg-orange-500"></div>
//                     <span className="ml-3 text-sm font-medium text-gray-700">Generate AI Summary</span>
//                 </label>
//             </div>

//             {/* Settings Summary */}
//             <div className="mt-4 p-4 bg-orange-50 rounded-lg">
//                 <div className="flex items-start gap-3">
//                     <CheckCircle2 className="w-5 h-5 text-orange-500 mt-0.5" />
//                     <div>
//                         <h4 className="font-medium text-orange-800">Interview Settings</h4>
//                         <div className="mt-1 text-sm text-orange-700 space-y-1">
//                             <p>{formData.company} - {formData.language}</p>
//                             {formData.simpleEnglish && (
//                                 <p>• Using simplified English</p>
//                             )}
//                             {formData.generateSummary && (
//                                 <p>• Will generate interview summary</p>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>

//         <div className="px-6 py-4 bg-gray-50 flex justify-between items-center">
//             <button
//                 onClick={onBack}
//                 className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
//             >
//                 Back
//             </button>
//             <button
//                 onClick={onNext}
//                 className="px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
//             >
//                 Continue
//             </button>
//         </div>
//     </div>
// );

// // Step 3: Meeting Setup
// const MeetingSetupStep = ({ onNext, onBack, videoRef, meetingStream, handleMeetingSourceSelect }) => (
//     <div className="space-y-6">
//         <div className="p-6 border-b border-gray-200">
//             <div className="flex items-center space-x-2">
//                 <Share2 className="w-6 h-6 text-orange-500" />
//                 <h2 className="text-xl font-semibold text-gray-900">Connect Meeting</h2>
//             </div>
//             <p className="mt-2 text-gray-500">Select your meeting tab (Zoom, Meet, or Teams)</p>
//         </div>

//         <div className="px-6">
//             {!meetingStream ? (
//                 <div className="bg-black rounded-lg aspect-video flex flex-col items-center justify-center text-white p-8">
//                     <button
//                         onClick={() => handleMeetingSourceSelect('tab')}
//                         className="flex items-center gap-2 px-6 py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
//                     >
//                         <Share2 className="w-5 h-5" />
//                         Select Meeting Tab
//                     </button>
//                     <p className="mt-6 text-sm text-gray-400 text-center">
//                         Choose your meeting tab to enable screen sharing
//                     </p>
//                 </div>
//             ) : (
//                 <div className="space-y-4">
//                     <div className="relative bg-black rounded-lg overflow-hidden">
//                         <video
//                             ref={videoRef}
//                             autoPlay
//                             playsInline
//                             className="w-full aspect-video"
//                         />
//                     </div>
//                     <div className="flex items-center justify-between">
//                         <div className="flex items-center gap-2">
//                             <span className="inline-block w-2 h-2 bg-green-500 rounded-full"></span>
//                             <span className="text-sm text-gray-600">Connected</span>
//                         </div>
//                         <button
//                             onClick={onNext}
//                             className="px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
//                         >
//                             Start Interview
//                         </button>
//                     </div>
//                 </div>
//             )}
//         </div>

//         <div className="px-6">
//             <div className="p-4 bg-blue-50 rounded-lg">
//                 <div className="flex items-start gap-3">
//                     <AlertCircle className="w-5 h-5 text-blue-600 mt-0.5" />
//                     <div>
//                         <h4 className="font-medium text-blue-800">Important Note</h4>
//                         <p className="mt-1 text-sm text-blue-600">
//                             Your meeting window will automatically appear in Picture-in-Picture mode.
//                         </p>
//                     </div>
//                 </div>
//             </div>
//         </div>

//         <div className="px-6 py-4 bg-gray-50 flex justify-between items-center">
//             <button
//                 onClick={onBack}
//                 className="px-4 py-2 text-gray-600 hover:text-gray-800 transition-colors"
//             >
//                 Back
//             </button>
//         </div>
//     </div>
// );

// const SessionSetupFlow = ({ onComplete, onCancel }) => {
//     const [stage, setStage] = useState('loading');
//     const [step, setStep] = useState(1);
//     const [isConnecting, setIsConnecting] = useState(true);
//     const [meetingStream, setMeetingStream] = useState(null);
//     const [sessionId] = useState(generateId());
//     const [userId] = useState(() => {
//         const existingUserId = localStorage.getItem('userId');
//         if (existingUserId) return existingUserId;

//         const newUserId = generateId();
//         localStorage.setItem('userId', newUserId);
//         return newUserId;
//     });

//     const [formData, setFormData] = useState({
//         company: '',
//         jobDescription: '',
//         language: 'English',
//         specialInstructions: '',
//         simpleEnglish: false,
//         generateSummary: true
//     });

//     const videoRef = useRef(null);

//     useEffect(() => {
//         const initializeSession = async () => {
//             try {
//                 // Check for existing sessions for this user
//                 const { data: existingSessions, error } = await supabase
//                     .from('interview_sessions')
//                     .select('*')
//                     .eq('user_id', userId)
//                     .order('created_at', { ascending: false })
//                     .limit(1);

//                 if (error) throw error;

//                 // Use existing session data if available
//                 if (existingSessions && existingSessions.length > 0) {
//                     const lastSession = existingSessions[0];
//                     setFormData({
//                         company: lastSession.company_name || '',
//                         jobDescription: lastSession.job_description || '',
//                         language: lastSession.interview_language || 'English',
//                         specialInstructions: lastSession.special_instructions || '',
//                         simpleEnglish: lastSession.simple_english || false,
//                         generateSummary: lastSession.generate_summary || true
//                     });
//                 }
//             } catch (error) {
//                 console.error('Error fetching session data:', error);
//             }

//             // Continue with setup
//             setIsConnecting(false);
//             setStage('setup');
//         };

//         setTimeout(initializeSession, 2000); // Keep the loading animation for better UX

//         return () => {
//             cleanupStream();
//         };
//     }, [userId]); // Add userId to dependencies

//     const cleanupStream = () => {
//         if (meetingStream) {
//             if (meetingStream.processStream) {
//                 meetingStream.processStream.getTracks().forEach(track => track.stop());
//             }
//             if (meetingStream.videoStream) {
//                 meetingStream.videoStream.getTracks().forEach(track => track.stop());
//             }
//             setMeetingStream(null);
//         }
//     };

//     const handleMeetingSourceSelect = async () => {
//         try {
//             cleanupStream();

//             const displayMedia = await navigator.mediaDevices.getDisplayMedia({
//                 video: {
//                     width: { ideal: 1920 },
//                     height: { ideal: 1080 },
//                     frameRate: { ideal: 30 }
//                 },
//                 audio: {
//                     suppressLocalAudioPlayback: false,
//                     echoCancellation: true,
//                     noiseSuppression: true,
//                     autoGainControl: true
//                 }
//             });

//             const audioTracks = displayMedia.getAudioTracks();
//             if (audioTracks.length === 0) {
//                 console.warn('No audio tracks found');
//                 alert('No audio detected. Please ensure you have selected a tab/window with audio.');
//                 return;
//             }

//             const videoTrack = displayMedia.getVideoTracks()[0];
//             const audioTrack = audioTracks[0];

//             const processStream = new MediaStream([
//                 videoTrack.clone(),
//                 audioTrack.clone()
//             ]);

//             const videoStream = new MediaStream([
//                 videoTrack,
//                 audioTrack
//             ]);

//             setMeetingStream({
//                 processStream,
//                 videoStream
//             });

//             if (videoRef.current) {
//                 videoRef.current.srcObject = videoStream;
//                 videoRef.current.muted = false;
//                 await videoRef.current.play();

//                 // Automatically enter PiP mode and center it
//                 try {
//                     if (document.pictureInPictureEnabled) {
//                         const pipWindow = await videoRef.current.requestPictureInPicture();
//                         // Center the PiP window
//                         if (pipWindow) {
//                             const screenWidth = window.screen.width;
//                             const screenHeight = window.screen.height;
//                             const pipWidth = 400; // Default PiP width
//                             const pipHeight = 225; // Default PiP height (16:9 ratio)

//                             // Set PiP window to center of screen
//                             if (pipWindow.windowId) {
//                                 const x = (screenWidth - pipWidth) / 2;
//                                 const y = (screenHeight - pipHeight) / 2;
//                                 pipWindow.style.transform = `translate(${x}px, ${y}px)`;
//                             }
//                         }
//                     }
//                 } catch (pipError) {
//                     console.warn('PiP initialization error:', pipError);
//                 }
//             }

//             const handleTrackEnd = () => {
//                 cleanupStream();
//                 setStage('setup');
//             };

//             videoTrack.onended = handleTrackEnd;
//             audioTrack.onended = handleTrackEnd;

//         } catch (error) {
//             console.error('Meeting source selection error:', error);
//             if (error.name === 'NotAllowedError') {
//                 alert('Please select your meeting tab/window to continue.');
//             } else if (error.name === 'NotFoundError') {
//                 alert('No audio or video source found. Please ensure your screen has audio.');
//             } else {
//                 alert('Failed to access screen sharing. Please try again.');
//             }
//         }
//     };

//     const handleNext = async () => {
//         if (step === 3) {
//             // Prepare complete session data
//             const sessionData = {
//                 sessionId,
//                 userId,
//                 timestamp: new Date().toISOString(),
//                 status: 'created',
//                 settings: {
//                     ...formData,
//                     isPremium: false, // Set this based on your premium detection logic
//                     createdAt: new Date().toISOString(),
//                     lastUpdated: new Date().toISOString()
//                 },
//                 meetingInfo: {
//                     hasAudio: !!meetingStream?.videoStream?.getAudioTracks().length,
//                     hasVideo: !!meetingStream?.videoStream?.getVideoTracks().length,
//                 }
//             };

//             try {
//                 // Save to Supabase
//                 const savedSession = await saveSessionToSupabase(sessionData);

//                 if (savedSession) {
//                     console.log('Session saved to Supabase:', savedSession);
//                 }

//                 // Complete setup with all data
//                 onComplete({
//                     ...sessionData,
//                     stream: meetingStream?.processStream,
//                     displayStream: meetingStream?.videoStream,
//                     videoRef,
//                     dbSession: savedSession
//                 });
//             } catch (error) {
//                 console.error('Error in session setup:', error);
//                 // Continue with the session even if save fails
//                 onComplete({
//                     ...sessionData,
//                     stream: meetingStream?.processStream,
//                     displayStream: meetingStream?.videoStream,
//                     videoRef
//                 });
//             }
//         } else {
//             setStep(step + 1);
//         }
//     };

//     const handleBack = () => {
//         setStep(Math.max(1, step - 1));
//     };

//     // Loading Screen
//     if (stage === 'loading') {
//         return (
//             <div className="fixed inset-0 bg-white flex flex-col items-center justify-center">
//                 <motion.div
//                     animate={{ rotate: 360 }}
//                     transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
//                     className="w-12 h-12 border-4 border-orange-500 border-t-transparent rounded-full"
//                 />
//                 <h2 className="mt-4 text-xl font-semibold">Preparing Your Interview</h2>
//                 <p className="mt-2 text-gray-500">
//                     Connecting to <span className="text-orange-500 font-medium">interview server</span>
//                 </p>
//             </div>
//         );
//     }

//     return (
//         <div className="fixed inset-0 bg-gray-100 flex flex-col">
//             {/* Header */}
//             <div className="bg-white border-b px-6 py-4">
//                 <div className="max-w-4xl mx-auto flex items-center justify-between">
//                     <div className="flex items-center gap-2">
//                         <h1 className="text-xl font-semibold">Interview Setup</h1>
//                         <span className="px-2 py-0.5 bg-orange-100 text-orange-600 rounded-full text-sm font-medium">
//                             Premium
//                         </span>
//                     </div>
//                     <button
//                         onClick={onCancel}
//                         className="p-2 hover:bg-gray-100 rounded-full transition-colors"
//                     >
//                         <X className="w-5 h-5" />
//                     </button>
//                 </div>
//             </div>

//             {/* Main Content */}
//             <div className="flex-1 overflow-y-auto">
//                 <div className="max-w-4xl mx-auto py-8">
//                     <div className="bg-white rounded-lg shadow-sm">
//                         <StepIndicator currentStep={step} />

//                         {step === 1 && (
//                             <InterviewDetailsStep
//                                 onNext={handleNext}
//                                 formData={formData}
//                                 setFormData={setFormData}
//                             />
//                         )}

//                         {step === 2 && (
//                             <AiSettingsStep
//                                 onNext={handleNext}
//                                 onBack={handleBack}
//                                 formData={formData}
//                                 setFormData={setFormData}
//                             />
//                         )}

//                         {step === 3 && (
//                             <MeetingSetupStep
//                                 onNext={handleNext}
//                                 onBack={handleBack}
//                                 videoRef={videoRef}
//                                 meetingStream={meetingStream}
//                                 handleMeetingSourceSelect={handleMeetingSourceSelect}
//                             />
//                         )}
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default SessionSetupFlow;

// import React, { useState, useRef, useEffect } from 'react';
// import { motion } from 'framer-motion';
// import {
//     Share2,
//     AlertCircle,
//     Maximize2
// } from 'lucide-react';

// const SessionSetupFlow = ({ onComplete, onCancel }) => {
//     const [stage, setStage] = useState('loading');
//     const [isConnecting, setIsConnecting] = useState(true);
//     const [meetingStream, setMeetingStream] = useState(null);
//     const [isPiPActive, setIsPiPActive] = useState(false);
//     const [pipError, setPipError] = useState(null);

//     const videoRef = useRef(null);

//     useEffect(() => {
//         // Check PiP support on mount
//         setPipError(!document.pictureInPictureEnabled ? 'Your browser does not support Picture-in-Picture mode.' : null);

//         // Simulate initial connection
//         setTimeout(() => {
//             setIsConnecting(false);
//             setStage('select-meeting');
//         }, 2000);

//         return () => {
//             cleanupStream();
//         };
//     }, []);

//     const cleanupStream = () => {
//         if (meetingStream) {
//             if (meetingStream.processStream) {
//                 meetingStream.processStream.getTracks().forEach(track => track.stop());
//             }
//             if (meetingStream.videoStream) {
//                 meetingStream.videoStream.getTracks().forEach(track => track.stop());
//             }
//             setMeetingStream(null);
//         }
//     };

//     const handleMeetingSourceSelect = async (sourceType) => {
//         try {
//             // Clear any existing errors
//             setPipError(null);

//             // Cleanup existing stream
//             cleanupStream();

//             console.log('Requesting display media...');
//             const displayMedia = await navigator.mediaDevices.getDisplayMedia({
//                 video: {
//                     width: { ideal: 1920 },
//                     height: { ideal: 1080 },
//                     frameRate: { ideal: 30 }
//                 },
//                 audio: {
//                     suppressLocalAudioPlayback: false,
//                     echoCancellation: true,
//                     noiseSuppression: true,
//                     autoGainControl: true
//                 }
//             });

//             const videoTrack = displayMedia.getVideoTracks()[0];
//             const audioTrack = displayMedia.getAudioTracks()[0];

//             // Create a new MediaStream with cloned tracks for our AI processing
//             const processStream = new MediaStream([
//                 videoTrack.clone(),
//                 audioTrack.clone()
//             ]);

//             // Create a new MediaStream for the video element
//             const videoStream = new MediaStream([
//                 videoTrack,
//                 audioTrack
//             ]);

//             console.log('Setting up streams...');
//             setMeetingStream({
//                 processStream,  // For AI processing
//                 videoStream    // For video display and PiP
//             });

//             console.log('Streams initialized:', {
//                 hasProcessStream: !!processStream,
//                 hasVideoStream: !!videoStream,
//                 videoTracks: videoStream.getVideoTracks().length,
//                 audioTracks: videoStream.getAudioTracks().length
//             });

//             if (videoRef.current) {
//                 videoRef.current.srcObject = videoStream;
//                 videoRef.current.muted = false;
//                 await videoRef.current.play();
//             }

//             const handleTrackEnd = () => {
//                 cleanupStream();
//                 setStage('select-meeting');
//                 setIsPiPActive(false);
//             };

//             videoTrack.onended = handleTrackEnd;
//             audioTrack.onended = handleTrackEnd;

//             setStage('ready');
//         } catch (error) {
//             console.error('Meeting source selection error:', error);
//             if (error.name === 'NotAllowedError') {
//                 alert('Please select your meeting tab/window to continue.');
//             } else {
//                 alert('Failed to access screen sharing. Please try again.');
//             }
//         }
//     };

//     const togglePiP = async () => {
//         try {
//             // First check if we're exiting PiP
//             if (document.pictureInPictureElement) {
//                 await document.exitPictureInPicture();
//                 setIsPiPActive(false);
//                 return;
//             }

//             // Validate stream existence
//             if (!meetingStream?.videoStream) {
//                 setPipError('Please share your meeting tab first before enabling Picture-in-Picture.');
//                 return;
//             }

//             // Validate video element
//             if (!videoRef.current) {
//                 setPipError('Video element not initialized. Please try refreshing the page.');
//                 return;
//             }

//             // Ensure we have an active stream
//             if (!videoRef.current.srcObject) {
//                 videoRef.current.srcObject = meetingStream.videoStream;
//             }

//             // Ensure video is playing
//             if (videoRef.current.paused) {
//                 try {
//                     await videoRef.current.play();
//                 } catch (playError) {
//                     console.error('Error playing video:', playError);
//                     setPipError('Failed to play video stream. Please ensure you have granted all necessary permissions.');
//                     return;
//                 }
//             }

//             const pipWindow = await videoRef.current.requestPictureInPicture();

//             if (pipWindow.width && pipWindow.height) {
//                 try {
//                     await pipWindow.setWidth(480);
//                     await pipWindow.setHeight(270);
//                 } catch (e) {
//                     console.warn('Failed to set PiP size:', e);
//                 }
//             }

//             setIsPiPActive(true);
//         } catch (error) {
//             console.error('PiP error:', error);
//             setPipError(error.message || 'Failed to enable Picture-in-Picture. Please try again.');
//         }
//     };

//     // Loading Screen
//     if (stage === 'loading') {
//         return (
//             <div className="fixed inset-0 bg-white flex flex-col items-center justify-center">
//                 <motion.div
//                     animate={{ rotate: 360 }}
//                     transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
//                     className="w-12 h-12 border-4 border-orange-500 border-t-transparent rounded-full"
//                 />
//                 <h2 className="mt-4 text-xl font-semibold">Session loading...</h2>
//                 <p className="mt-2 text-gray-500">
//                     We are <span className="text-orange-500 font-medium">connecting to copilot server</span>
//                 </p>
//             </div>
//         );
//     }

//     return (
//         <div className="min-h-screen bg-gray-50 p-8">
//             <div className="max-w-xl mx-auto">
//                 <div className="bg-white rounded-lg shadow-sm p-6">
//                     {/* Header */}
//                     <div className="flex items-center justify-between mb-6">
//                         <h1 className="text-2xl font-semibold">Live Interview Setup</h1>
//                         <div className="flex items-center gap-2">
//                             <span className="px-3 py-1 bg-orange-100 text-orange-600 rounded-full text-sm font-medium">
//                                 Premium
//                             </span>
//                         </div>
//                     </div>

//                     <div className="space-y-6">
//                         {/* Meeting Source Selection */}
//                         {stage === 'select-meeting' && (
//                             <div className="text-center py-4">
//                                 <h2 className="text-xl font-semibold mb-4">
//                                     Select Meeting Source
//                                 </h2>
//                                 <div className="flex flex-col items-center gap-6">
//                                     <div className="flex justify-center gap-4">
//                                         <button
//                                             onClick={() => handleMeetingSourceSelect('tab')}
//                                             className="flex items-center gap-2 px-4 py-2 rounded-lg border hover:bg-gray-50 transition-colors"
//                                         >
//                                             <Share2 className="w-5 h-5" />
//                                             Share Meeting Tab
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         )}

//                         {/* PiP Setup */}
//                         {stage === 'ready' && (
//                             <div className="space-y-4">
//                                 <div className="relative">
//                                     <video
//                                         ref={videoRef}
//                                         autoPlay
//                                         playsInline
//                                         controls
//                                         className="w-full rounded-lg bg-black aspect-video"
//                                     />
//                                     {!pipError && (
//                                         <motion.button
//                                             initial={{ scale: 1 }}
//                                             animate={{ scale: [1, 1.05, 1] }}
//                                             transition={{ duration: 2, repeat: Infinity }}
//                                             onClick={togglePiP}
//                                             className="absolute bottom-4 right-4 inline-flex items-center gap-2 px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 shadow-lg"
//                                         >
//                                             <Maximize2 className="w-5 h-5" />
//                                             {isPiPActive ? 'Exit Picture-in-Picture' : 'Enable Picture-in-Picture'}
//                                         </motion.button>
//                                     )}
//                                 </div>

//                                 {pipError && (
//                                     <div className="bg-red-50 p-4 rounded-lg">
//                                         <div className="flex items-start gap-3">
//                                             <AlertCircle className="w-5 h-5 text-red-600 mt-0.5" />
//                                             <div>
//                                                 <h4 className="font-medium text-red-800">Picture-in-Picture Error</h4>
//                                                 <p className="mt-1 text-sm text-red-600">{pipError}</p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 )}

//                                 {!isPiPActive && !pipError && (
//                                     <div className="bg-blue-50 p-4 rounded-lg">
//                                         <div className="flex items-start gap-3">
//                                             <AlertCircle className="w-5 h-5 text-blue-600 mt-0.5" />
//                                             <div>
//                                                 <h4 className="font-medium text-blue-800">Enable Picture-in-Picture</h4>
//                                                 <p className="mt-1 text-sm text-blue-600">
//                                                     Click the orange button above to keep your meeting visible while you use our interview platform.
//                                                 </p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 )}

//                                 <div className="flex justify-end gap-3 mt-6">
//                                     <button
//                                         onClick={onCancel}
//                                         className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
//                                     >
//                                         Cancel
//                                     </button>
//                                     <button
//                                         onClick={() => onComplete({
//                                             stream: meetingStream.processStream,
//                                             displayStream: meetingStream.videoStream,
//                                             videoRef,
//                                             togglePiP
//                                         })}
//                                         className="px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
//                                     >
//                                         Start Interview
//                                     </button>
//                                 </div>
//                             </div>
//                         )}

//                         {/* Helper Text */}
//                         <div className="mt-6 p-4 bg-blue-50 rounded-lg">
//                             <div className="flex items-start gap-3">
//                                 <AlertCircle className="w-5 h-5 text-blue-600 mt-0.5" />
//                                 <div>
//                                     <h4 className="font-medium text-blue-800">Important Note</h4>
//                                     <p className="mt-1 text-sm text-blue-600">
//                                         {stage === 'select-meeting'
//                                             ? 'Select your meeting tab from Google Meet, Zoom, or Microsoft Teams to continue.'
//                                             : 'Use Picture-in-Picture mode to keep your meeting visible while using our platform.'}
//                                     </p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// // export default SessionSetupFlow;

// import React, { useState, useRef, useEffect } from 'react';
// import { motion } from 'framer-motion';
// import {
//     Share2,
//     AlertCircle,
//     Maximize2
// } from 'lucide-react';

// const SessionSetupFlow = ({ onComplete, onCancel }) => {
//     const [stage, setStage] = useState('loading');
//     const [isConnecting, setIsConnecting] = useState(true);
//     const [meetingStream, setMeetingStream] = useState(null);
//     const [isPiPActive, setIsPiPActive] = useState(false);
//     const [pipError, setPipError] = useState(null);

//     const videoRef = useRef(null);

//     useEffect(() => {
//         // Check PiP support on mount
//         setPipError(!document.pictureInPictureEnabled ? 'Your browser does not support Picture-in-Picture mode.' : null);

//         // Simulate initial connection
//         setTimeout(() => {
//             setIsConnecting(false);
//             setStage('select-meeting');
//         }, 2000);

//         return () => {
//             cleanupStream();
//         };
//     }, []);

//     const cleanupStream = () => {
//         if (meetingStream) {
//             if (meetingStream.processStream) {
//                 meetingStream.processStream.getTracks().forEach(track => track.stop());
//             }
//             if (meetingStream.videoStream) {
//                 meetingStream.videoStream.getTracks().forEach(track => track.stop());
//             }
//             setMeetingStream(null);
//         }
//     };

//     const handleMeetingSourceSelect = async (sourceType) => {
//         try {
//             // Clear any existing errors
//             setPipError(null);

//             // Cleanup existing stream
//             cleanupStream();

//             console.log('Requesting display media...');
//             const displayMedia = await navigator.mediaDevices.getDisplayMedia({
//                 video: {
//                     width: { ideal: 1920 },
//                     height: { ideal: 1080 },
//                     frameRate: { ideal: 30 }
//                 },
//                 audio: {
//                     suppressLocalAudioPlayback: false,
//                     echoCancellation: true,
//                     noiseSuppression: true,
//                     autoGainControl: true
//                 }
//             });

//             const videoTrack = displayMedia.getVideoTracks()[0];
//             const audioTrack = displayMedia.getAudioTracks()[0];

//             // Create a new MediaStream with cloned tracks for our AI processing
//             const processStream = new MediaStream([
//                 videoTrack.clone(),
//                 audioTrack.clone()
//             ]);

//             // Create a new MediaStream for the video element
//             const videoStream = new MediaStream([
//                 videoTrack,
//                 audioTrack
//             ]);

//             console.log('Setting up streams...');
//             setMeetingStream({
//                 processStream,  // For AI processing
//                 videoStream    // For video display and PiP
//             });

//             console.log('Streams initialized:', {
//                 hasProcessStream: !!processStream,
//                 hasVideoStream: !!videoStream,
//                 videoTracks: videoStream.getVideoTracks().length,
//                 audioTracks: videoStream.getAudioTracks().length
//             });

//             if (videoRef.current) {
//                 videoRef.current.srcObject = videoStream;
//                 videoRef.current.muted = false;
//                 await videoRef.current.play();
//             }

//             const handleTrackEnd = () => {
//                 cleanupStream();
//                 setStage('select-meeting');
//                 setIsPiPActive(false);
//             };

//             videoTrack.onended = handleTrackEnd;
//             audioTrack.onended = handleTrackEnd;

//             setStage('ready');
//         } catch (error) {
//             console.error('Meeting source selection error:', error);
//             if (error.name === 'NotAllowedError') {
//                 alert('Please select your meeting tab/window to continue.');
//             } else {
//                 alert('Failed to access screen sharing. Please try again.');
//             }
//         }
//     };

//     const togglePiP = async () => {
//         try {
//             // First check if we're exiting PiP
//             if (document.pictureInPictureElement) {
//                 await document.exitPictureInPicture();
//                 setIsPiPActive(false);
//                 return;
//             }

//             // Validate stream existence
//             if (!meetingStream?.videoStream) {
//                 setPipError('Please share your meeting tab first before enabling Picture-in-Picture.');
//                 return;
//             }

//             // Validate video element
//             if (!videoRef.current) {
//                 setPipError('Video element not initialized. Please try refreshing the page.');
//                 return;
//             }

//             // Ensure we have an active stream
//             if (!videoRef.current.srcObject) {
//                 videoRef.current.srcObject = meetingStream.videoStream;
//             }

//             // Ensure video is playing
//             if (videoRef.current.paused) {
//                 try {
//                     await videoRef.current.play();
//                 } catch (playError) {
//                     console.error('Error playing video:', playError);
//                     setPipError('Failed to play video stream. Please ensure you have granted all necessary permissions.');
//                     return;
//                 }
//             }

//             const pipWindow = await videoRef.current.requestPictureInPicture();

//             if (pipWindow.width && pipWindow.height) {
//                 try {
//                     await pipWindow.setWidth(480);
//                     await pipWindow.setHeight(270);
//                 } catch (e) {
//                     console.warn('Failed to set PiP size:', e);
//                 }
//             }

//             setIsPiPActive(true);
//         } catch (error) {
//             console.error('PiP error:', error);
//             setPipError(error.message || 'Failed to enable Picture-in-Picture. Please try again.');
//         }
//     };

//     // Loading Screen
//     if (stage === 'loading') {
//         return (
//             <div className="fixed inset-0 bg-white flex flex-col items-center justify-center">
//                 <motion.div
//                     animate={{ rotate: 360 }}
//                     transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
//                     className="w-12 h-12 border-4 border-orange-500 border-t-transparent rounded-full"
//                 />
//                 <h2 className="mt-4 text-xl font-semibold">Session loading...</h2>
//                 <p className="mt-2 text-gray-500">
//                     We are <span className="text-orange-500 font-medium">connecting to copilot server</span>
//                 </p>
//             </div>
//         );
//     }

//     return (
//         <div className="min-h-screen bg-white flex flex-col">
//             {/* Header */}
//             <div className="border-b">
//                 <div className="flex items-center justify-between px-4 py-2">
//                     <div className="flex items-center gap-2">
//                         <h1 className="text-lg font-medium">Live Interview</h1>
//                         <span className="px-2 py-0.5 bg-orange-100 text-orange-600 rounded-full text-sm font-medium">
//                             Premium
//                         </span>
//                     </div>
//                     <div className="text-sm text-gray-500">
//                         00:00
//                     </div>
//                 </div>
//             </div>

//             <div className="flex-1 p-4">
//                 <div className="max-w-4xl mx-auto">
//                     {/* Meeting Source Selection */}
//                     {stage === 'select-meeting' && (
//                         <div className="bg-black rounded-lg aspect-video flex flex-col items-center justify-center text-white p-8">
//                             <h2 className="text-xl font-medium mb-6">
//                                 Connect to your interview meeting room
//                             </h2>
//                             <button
//                                 onClick={() => handleMeetingSourceSelect('tab')}
//                                 className="flex items-center gap-2 px-6 py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
//                             >
//                                 <Share2 className="w-5 h-5" />
//                                 Select
//                             </button>
//                             <p className="mt-6 text-sm text-gray-400 text-center">
//                                 Once you have selected the interview meeting room<br />
//                                 the transcript will be displayed here.
//                             </p>
//                         </div>
//                     )}

//                     {/* PiP Setup */}
//                     {stage === 'ready' && (
//                         <div>
//                             <div className="relative bg-black rounded-lg overflow-hidden">
//                                 <video
//                                     ref={videoRef}
//                                     autoPlay
//                                     playsInline
//                                     controls
//                                     className="w-full aspect-video"
//                                 />
//                                 {!pipError && (
//                                     <button
//                                         onClick={togglePiP}
//                                         className="absolute bottom-4 right-4 inline-flex items-center gap-2 px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 shadow-lg"
//                                     >
//                                         <Maximize2 className="w-5 h-5" />
//                                         {isPiPActive ? 'Exit Picture-in-Picture' : 'Enable Picture-in-Picture'}
//                                     </button>
//                                 )}
//                             </div>

//                             {/* Errors and Status */}
//                             <div className="mt-4 space-y-4">
//                                 {pipError && (
//                                     <div className="bg-red-50 border border-red-100 rounded-lg p-4">
//                                         <div className="flex items-start gap-3">
//                                             <AlertCircle className="w-5 h-5 text-red-600 mt-0.5 flex-shrink-0" />
//                                             <div>
//                                                 <h4 className="font-medium text-red-800">Picture-in-Picture Error</h4>
//                                                 <p className="mt-1 text-sm text-red-600">{pipError}</p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 )}

//                                 <div className="flex items-center justify-between">
//                                     <div className="flex items-center gap-4">
//                                         <div className="flex items-center gap-2">
//                                             <span className="inline-block w-2 h-2 bg-green-500 rounded-full"></span>
//                                             <span className="text-sm text-gray-600">Ready</span>
//                                         </div>
//                                         <div className="flex items-center gap-2">
//                                             <span className="text-sm text-gray-600">AI Responses:</span>
//                                             <span className="inline-block w-2 h-2 bg-green-500 rounded-full"></span>
//                                             <span className="text-sm text-gray-600">Ready</span>
//                                         </div>
//                                     </div>

//                                     {/* Controls moved to right side */}
//                                     <div className="flex items-center gap-3">
//                                         <button
//                                             onClick={onCancel}
//                                             className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
//                                         >
//                                             Cancel
//                                         </button>
//                                         <button
//                                             onClick={() => onComplete({
//                                                 stream: meetingStream.processStream,
//                                                 displayStream: meetingStream.videoStream,
//                                                 videoRef,
//                                                 togglePiP
//                                             })}
//                                             className="px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
//                                         >
//                                             Start Interview
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {/* Helper Text */}
//                     <div className="mt-6 p-4 bg-blue-50 rounded-lg">
//                         <div className="flex items-start gap-3">
//                             <AlertCircle className="w-5 h-5 text-blue-600 mt-0.5" />
//                             <div>
//                                 <h4 className="font-medium text-blue-800">Important Note</h4>
//                                 <p className="mt-1 text-sm text-blue-600">
//                                     {stage === 'select-meeting'
//                                         ? 'Select your meeting tab from Google Meet, Zoom, or Microsoft Teams to continue.'
//                                         : 'Use Picture-in-Picture mode to keep your meeting visible while using our platform.'}
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default SessionSetupFlow;


// import React, { useState, useRef, useEffect } from 'react';
// import { motion } from 'framer-motion';
// import {
//     Share2,
//     AlertCircle,
//     Maximize2
// } from 'lucide-react';

// const SessionSetupFlow = ({ onComplete, onCancel }) => {
//     const [stage, setStage] = useState('loading');
//     const [isConnecting, setIsConnecting] = useState(true);
//     const [meetingStream, setMeetingStream] = useState(null);
//     const [isPiPActive, setIsPiPActive] = useState(false);
//     const [pipError, setPipError] = useState(null);

//     const videoRef = useRef(null);

//     useEffect(() => {
//         // Check PiP support on mount
//         setPipError(!document.pictureInPictureEnabled ? 'Your browser does not support Picture-in-Picture mode.' : null);

//         // Simulate initial connection
//         setTimeout(() => {
//             setIsConnecting(false);
//             setStage('select-meeting');
//         }, 2000);

//         return () => {
//             cleanupStream();
//         };
//     }, []);

//     const cleanupStream = () => {
//         if (meetingStream) {
//             if (meetingStream.processStream) {
//                 meetingStream.processStream.getTracks().forEach(track => track.stop());
//             }
//             if (meetingStream.videoStream) {
//                 meetingStream.videoStream.getTracks().forEach(track => track.stop());
//             }
//             setMeetingStream(null);
//         }
//     };

//     const handleMeetingSourceSelect = async (sourceType) => {
//         try {
//             // Clear any existing errors
//             setPipError(null);

//             // Cleanup existing stream
//             cleanupStream();

//             console.log('Requesting display media...');
//             const displayMedia = await navigator.mediaDevices.getDisplayMedia({
//                 video: {
//                     width: { ideal: 1920 },
//                     height: { ideal: 1080 },
//                     frameRate: { ideal: 30 }
//                 },
//                 audio: {
//                     suppressLocalAudioPlayback: false,
//                     echoCancellation: true,
//                     noiseSuppression: true,
//                     autoGainControl: true
//                 }
//             });

//             const videoTrack = displayMedia.getVideoTracks()[0];
//             const audioTrack = displayMedia.getAudioTracks()[0];

//             // Create a new MediaStream with cloned tracks for our AI processing
//             const processStream = new MediaStream([
//                 videoTrack.clone(),
//                 audioTrack.clone()
//             ]);

//             // Create a new MediaStream for the video element
//             const videoStream = new MediaStream([
//                 videoTrack,
//                 audioTrack
//             ]);

//             console.log('Setting up streams...');
//             setMeetingStream({
//                 processStream,  // For AI processing
//                 videoStream    // For video display and PiP
//             });

//             console.log('Streams initialized:', {
//                 hasProcessStream: !!processStream,
//                 hasVideoStream: !!videoStream,
//                 videoTracks: videoStream.getVideoTracks().length,
//                 audioTracks: videoStream.getAudioTracks().length
//             });

//             if (videoRef.current) {
//                 videoRef.current.srcObject = videoStream;
//                 videoRef.current.muted = false;
//                 await videoRef.current.play();
//             }

//             const handleTrackEnd = () => {
//                 cleanupStream();
//                 setStage('select-meeting');
//                 setIsPiPActive(false);
//             };

//             videoTrack.onended = handleTrackEnd;
//             audioTrack.onended = handleTrackEnd;

//             setStage('ready');
//         } catch (error) {
//             console.error('Meeting source selection error:', error);
//             if (error.name === 'NotAllowedError') {
//                 alert('Please select your meeting tab/window to continue.');
//             } else {
//                 alert('Failed to access screen sharing. Please try again.');
//             }
//         }
//     };

//     const togglePiP = async () => {
//         try {
//             // First check if we're exiting PiP
//             if (document.pictureInPictureElement) {
//                 await document.exitPictureInPicture();
//                 setIsPiPActive(false);
//                 return;
//             }

//             // Validate stream existence
//             if (!meetingStream?.videoStream) {
//                 setPipError('Please share your meeting tab first before enabling Picture-in-Picture.');
//                 return;
//             }

//             // Validate video element
//             if (!videoRef.current) {
//                 setPipError('Video element not initialized. Please try refreshing the page.');
//                 return;
//             }

//             // Ensure we have an active stream
//             if (!videoRef.current.srcObject) {
//                 videoRef.current.srcObject = meetingStream.videoStream;
//             }

//             // Ensure video is playing
//             if (videoRef.current.paused) {
//                 try {
//                     await videoRef.current.play();
//                 } catch (playError) {
//                     console.error('Error playing video:', playError);
//                     setPipError('Failed to play video stream. Please ensure you have granted all necessary permissions.');
//                     return;
//                 }
//             }

//             const pipWindow = await videoRef.current.requestPictureInPicture();

//             if (pipWindow.width && pipWindow.height) {
//                 try {
//                     await pipWindow.setWidth(480);
//                     await pipWindow.setHeight(270);
//                 } catch (e) {
//                     console.warn('Failed to set PiP size:', e);
//                 }
//             }

//             setIsPiPActive(true);
//         } catch (error) {
//             console.error('PiP error:', error);
//             setPipError(error.message || 'Failed to enable Picture-in-Picture. Please try again.');
//         }
//     };

//     // Loading Screen
//     if (stage === 'loading') {
//         return (
//             <div className="fixed inset-0 bg-white flex flex-col items-center justify-center">
//                 <motion.div
//                     animate={{ rotate: 360 }}
//                     transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
//                     className="w-12 h-12 border-4 border-orange-500 border-t-transparent rounded-full"
//                 />
//                 <h2 className="mt-4 text-xl font-semibold">Session loading...</h2>
//                 <p className="mt-2 text-gray-500">
//                     We are <span className="text-orange-500 font-medium">connecting to copilot server</span>
//                 </p>
//             </div>
//         );
//     }

//     return (
//         <div className="min-h-screen bg-gray-50 p-8">
//             <div className="max-w-xl mx-auto">
//                 <div className="bg-white rounded-lg shadow-sm p-6">
//                     {/* Header */}
//                     <div className="flex items-center justify-between mb-6">
//                         <h1 className="text-2xl font-semibold">Live Interview Setup</h1>
//                         <div className="flex items-center gap-2">
//                             <span className="px-3 py-1 bg-orange-100 text-orange-600 rounded-full text-sm font-medium">
//                                 Premium
//                             </span>
//                         </div>
//                     </div>

//                     <div className="space-y-6">
//                         {/* Meeting Source Selection */}
//                         {stage === 'select-meeting' && (
//                             <div className="text-center py-4">
//                                 <h2 className="text-xl font-semibold mb-4">
//                                     Select Meeting Source
//                                 </h2>
//                                 <div className="flex flex-col items-center gap-6">
//                                     <div className="flex justify-center gap-4">
//                                         <button
//                                             onClick={() => handleMeetingSourceSelect('tab')}
//                                             className="flex items-center gap-2 px-4 py-2 rounded-lg border hover:bg-gray-50 transition-colors"
//                                         >
//                                             <Share2 className="w-5 h-5" />
//                                             Share Meeting Tab
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         )}

//                         {/* PiP Setup */}
//                         {stage === 'ready' && (
//                             <div className="space-y-4">
//                                 <div className="relative">
//                                     <video
//                                         ref={videoRef}
//                                         autoPlay
//                                         playsInline
//                                         controls
//                                         className="w-full rounded-lg bg-black aspect-video"
//                                     />
//                                     {!pipError && (
//                                         <motion.button
//                                             initial={{ scale: 1 }}
//                                             animate={{ scale: [1, 1.05, 1] }}
//                                             transition={{ duration: 2, repeat: Infinity }}
//                                             onClick={togglePiP}
//                                             className="absolute bottom-4 right-4 inline-flex items-center gap-2 px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 shadow-lg"
//                                         >
//                                             <Maximize2 className="w-5 h-5" />
//                                             {isPiPActive ? 'Exit Picture-in-Picture' : 'Enable Picture-in-Picture'}
//                                         </motion.button>
//                                     )}
//                                 </div>

//                                 {pipError && (
//                                     <div className="bg-red-50 p-4 rounded-lg">
//                                         <div className="flex items-start gap-3">
//                                             <AlertCircle className="w-5 h-5 text-red-600 mt-0.5" />
//                                             <div>
//                                                 <h4 className="font-medium text-red-800">Picture-in-Picture Error</h4>
//                                                 <p className="mt-1 text-sm text-red-600">{pipError}</p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 )}

//                                 {!isPiPActive && !pipError && (
//                                     <div className="bg-blue-50 p-4 rounded-lg">
//                                         <div className="flex items-start gap-3">
//                                             <AlertCircle className="w-5 h-5 text-blue-600 mt-0.5" />
//                                             <div>
//                                                 <h4 className="font-medium text-blue-800">Enable Picture-in-Picture</h4>
//                                                 <p className="mt-1 text-sm text-blue-600">
//                                                     Click the orange button above to keep your meeting visible while you use our interview platform.
//                                                 </p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 )}

//                                 <div className="flex justify-end gap-3 mt-6">
//                                     <button
//                                         onClick={onCancel}
//                                         className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
//                                     >
//                                         Cancel
//                                     </button>
//                                     <button
//                                         onClick={() => onComplete({
//                                             stream: meetingStream.processStream,
//                                             displayStream: meetingStream.videoStream,
//                                             videoRef,
//                                             togglePiP
//                                         })}
//                                         className="px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
//                                     >
//                                         Start Interview
//                                     </button>
//                                 </div>
//                             </div>
//                         )}

//                         {/* Helper Text */}
//                         <div className="mt-6 p-4 bg-blue-50 rounded-lg">
//                             <div className="flex items-start gap-3">
//                                 <AlertCircle className="w-5 h-5 text-blue-600 mt-0.5" />
//                                 <div>
//                                     <h4 className="font-medium text-blue-800">Important Note</h4>
//                                     <p className="mt-1 text-sm text-blue-600">
//                                         {stage === 'select-meeting'
//                                             ? 'Select your meeting tab from Google Meet, Zoom, or Microsoft Teams to continue.'
//                                             : 'Use Picture-in-Picture mode to keep your meeting visible while using our platform.'}
//                                     </p>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// // export default SessionSetupFlow;

// SessionSetupFlow.jsx
import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import {
    Share2,
    AlertCircle,
    Maximize2,
    Upload,
    CheckCircle2
} from 'lucide-react';

// Animation variants for the pulsating button
const pulsateButton = {
    initial: {
        scale: 1,
        boxShadow: '0 0 0 0 rgba(249, 115, 22, 0.7)'
    },
    animate: {
        scale: [1, 1.05, 1],
        boxShadow: [
            '0 0 0 0 rgba(249, 115, 22, 0.7)',
            '0 0 0 15px rgba(249, 115, 22, 0)',
            '0 0 0 0 rgba(249, 115, 22, 0)'
        ],
        transition: {
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut"
        }
    },
    hover: {
        scale: 1.1,
        transition: {
            duration: 0.2
        }
    },
    tap: {
        scale: 0.95
    }
};

const SessionSetupFlow = ({ onComplete, onCancel }) => {
    const [stage, setStage] = useState('loading');
    const [isConnecting, setIsConnecting] = useState(true);
    const [meetingStream, setMeetingStream] = useState(null);
    const [isPiPActive, setIsPiPActive] = useState(false);
    const [pipError, setPipError] = useState(null);
    const [hasInteractedWithPiP, setHasInteractedWithPiP] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const videoRef = useRef(null);

    // Listen for PiP changes from browser
    useEffect(() => {
        const handlePiPChange = () => {
            setIsPiPActive(!!document.pictureInPictureElement);
        };

        document.addEventListener('enterpictureinpicture', handlePiPChange);
        document.addEventListener('leavepictureinpicture', handlePiPChange);

        return () => {
            document.removeEventListener('enterpictureinpicture', handlePiPChange);
            document.removeEventListener('leavepictureinpicture', handlePiPChange);
        };
    }, []);

    // Persist PiP state
    useEffect(() => {
        if (isPiPActive && videoRef.current && !document.pictureInPictureElement) {
            videoRef.current.requestPictureInPicture().catch(error => {
                console.error('Failed to restore PiP:', error);
            });
        }
    }, [isPiPActive]);

    useEffect(() => {
        // Check PiP support
        setPipError(!document.pictureInPictureEnabled ? 'Your browser does not support Picture-in-Picture mode.' : null);

        // Simulate initial connection - in production this would be a real API call
        const connectionTimer = setTimeout(() => {
            setIsConnecting(false);
            setStage('select-meeting');
        }, 2000);

        return () => {
            clearTimeout(connectionTimer);
            cleanupStream();
        };
    }, []);

    const cleanupStream = () => {
        if (meetingStream) {
            if (meetingStream.processStream) {
                meetingStream.processStream.getTracks().forEach(track => track.stop());
            }
            if (meetingStream.videoStream) {
                meetingStream.videoStream.getTracks().forEach(track => track.stop());
            }
            setMeetingStream(null);
            setIsPiPActive(false);
            setIsReady(false);
        }
    };

    // const handleMeetingSourceSelect = async (sourceType) => {
    //     try {
    //         setPipError(null);
    //         cleanupStream();

    //         const displayMedia = await navigator.mediaDevices.getDisplayMedia({
    //             video: {
    //                 width: { ideal: 1920 },
    //                 height: { ideal: 1080 },
    //                 frameRate: { ideal: 30 }
    //             },
    //             audio: {
    //                 suppressLocalAudioPlayback: false,
    //                 echoCancellation: true,
    //                 noiseSuppression: true,
    //                 autoGainControl: true
    //             }
    //         });

    //         const videoTrack = displayMedia.getVideoTracks()[0];
    //         const audioTrack = displayMedia.getAudioTracks()[0];

    //         // Create two streams: one for AI processing and one for display
    //         const processStream = new MediaStream([
    //             videoTrack.clone(),
    //             audioTrack.clone()
    //         ]);

    //         const videoStream = new MediaStream([
    //             videoTrack,
    //             audioTrack
    //         ]);

    //         setMeetingStream({
    //             processStream,  // For AI processing
    //             videoStream    // For video display and PiP
    //         });

    //         if (videoRef.current) {
    //             videoRef.current.srcObject = videoStream;
    //             videoRef.current.muted = false;
    //             await videoRef.current.play();
    //         }

    //         const handleTrackEnd = () => {
    //             cleanupStream();
    //             setStage('select-meeting');
    //         };

    //         videoTrack.onended = handleTrackEnd;
    //         audioTrack.onended = handleTrackEnd;

    //         setStage('ready');
    //         setIsReady(true);
    //     } catch (error) {
    //         console.error('Meeting source selection error:', error);
    //         if (error.name === 'NotAllowedError') {
    //             alert('Please select your meeting tab/window to continue.');
    //         } else {
    //             alert('Failed to access screen sharing. Please try again.');
    //         }
    //     }
    // };

    const handleMeetingSourceSelect = async () => {
        try {
            setPipError(null);
            cleanupStream();

            const displayMedia = await navigator.mediaDevices.getDisplayMedia({
                video: {
                    width: { ideal: 1920 },
                    height: { ideal: 1080 },
                    frameRate: { ideal: 30 }
                },
                audio: {
                    suppressLocalAudioPlayback: false,
                    echoCancellation: true,
                    noiseSuppression: false,
                    autoGainControl: false
                }
            });

            const videoTrack = displayMedia.getVideoTracks()[0];
            const audioTrack = displayMedia.getAudioTracks()[0];

            // (1) AI processing stream: video + audio (cloned)
            const processStream = new MediaStream([
                videoTrack.clone(),
                audioTrack.clone()
            ]);

            // (2) Display/PiP stream: video only (NO audio track)
            const videoStream = new MediaStream([videoTrack]);
            // or if you prefer to keep the audio track but mute it:
            // const videoStream = new MediaStream([videoTrack, audioTrack]);
            // videoRef.current.muted = true;

            setMeetingStream({
                processStream, // for AI processing
                videoStream    // for local display & PiP
            });

            if (videoRef.current) {
                // Assign the video-only stream
                videoRef.current.srcObject = videoStream;
                // Mute is optional if you omitted audio
                videoRef.current.muted = true;
                await videoRef.current.play();
            }

            const handleTrackEnd = () => {
                cleanupStream();
                setStage('select-meeting');
            };

            videoTrack.onended = handleTrackEnd;
            if (audioTrack) {
                audioTrack.onended = handleTrackEnd;
            }

            setStage('ready');
            setIsReady(true);

        } catch (error) {
            console.error('Meeting source selection error:', error);
            if (error.name === 'NotAllowedError') {
                alert('Please select your meeting tab/window to continue.');
            } else {
                alert('Failed to access screen sharing. Please try again.');
            }
        }
    };


    const togglePiP = async () => {
        try {
            setHasInteractedWithPiP(true);

            if (document.pictureInPictureElement) {
                await document.exitPictureInPicture();
                setIsPiPActive(false);
                return;
            }

            if (!meetingStream?.videoStream) {
                setPipError('Please share your meeting tab first before enabling Picture-in-Picture.');
                return;
            }

            if (!videoRef.current) {
                setPipError('Video element not initialized. Please try refreshing the page.');
                return;
            }

            if (!videoRef.current.srcObject) {
                videoRef.current.srcObject = meetingStream.videoStream;
            }

            if (videoRef.current.paused) {
                try {
                    await videoRef.current.play();
                } catch (playError) {
                    console.error('Error playing video:', playError);
                    setPipError('Failed to play video stream. Please ensure you have granted all necessary permissions.');
                    return;
                }
            }

            const pipWindow = await videoRef.current.requestPictureInPicture();

            // Set optimal PiP window size
            if (pipWindow.width && pipWindow.height) {
                try {
                    await pipWindow.setWidth(480);
                    await pipWindow.setHeight(270);
                } catch (e) {
                    console.warn('Failed to set PiP size:', e);
                }
            }

            setIsPiPActive(true);
        } catch (error) {
            console.error('PiP error:', error);
            setPipError(error.message || 'Failed to enable Picture-in-Picture. Please try again.');
        }
    };

    // Loading Screen
    if (stage === 'loading') {
        return (
            <div className="fixed inset-0 bg-white flex flex-col items-center justify-center">
                <motion.div
                    animate={{ rotate: 360 }}
                    transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                    className="w-12 h-12 border-4 border-orange-500 border-t-transparent rounded-full"
                />
                <h2 className="mt-4 text-xl font-semibold">Session loading...</h2>
                <p className="mt-2 text-gray-500">
                    We are <span className="text-orange-500 font-medium">connecting to copilot server</span>
                </p>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-white flex flex-col">
            {/* Header */}
            <div className="border-b">
                <div className="flex items-center justify-between px-4 py-2">
                    <div className="flex items-center gap-2">
                        <h1 className="text-lg font-medium">Live Interview</h1>
                        <span className="px-2 py-0.5 bg-orange-100 text-orange-600 rounded-full text-sm font-medium">
                            Premium
                        </span>
                    </div>
                    <div className="text-sm text-gray-500">
                        00:00
                    </div>
                </div>
            </div>

            <div className="flex-1 p-4">
                <div className="max-w-4xl mx-auto">
                    {/* Meeting Source Selection */}
                    {stage === 'select-meeting' && (
                        <div className="bg-black rounded-lg aspect-video flex flex-col items-center justify-center text-white p-8">
                            <h2 className="text-xl font-medium mb-6">
                                Connect to your interview meeting room
                            </h2>
                            <motion.button
                                onClick={() => handleMeetingSourceSelect('tab')}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className="flex items-center gap-2 px-6 py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
                            >
                                <Share2 className="w-5 h-5" />
                                Select Meeting Tab
                            </motion.button>
                            <p className="mt-6 text-sm text-gray-400 text-center">
                                Once you have selected the interview meeting room<br />
                                the transcript will be displayed here.
                            </p>
                        </div>
                    )}

                    {/* PiP Setup */}
                    {stage === 'ready' && (
                        <div>
                            <div className="relative bg-black rounded-lg overflow-hidden">
                                <video
                                    ref={videoRef}
                                    autoPlay
                                    playsInline
                                    controls
                                    className="w-full aspect-video"
                                />
                                {!pipError && (
                                    <motion.button
                                        onClick={togglePiP}
                                        variants={pulsateButton}
                                        initial="initial"
                                        animate={!hasInteractedWithPiP ? "animate" : "initial"}
                                        whileHover="hover"
                                        whileTap="tap"
                                        className="absolute bottom-4 right-4 inline-flex items-center gap-2 px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 shadow-lg"
                                    >
                                        <Maximize2 className="w-5 h-5" />
                                        {isPiPActive ? 'Exit Picture-in-Picture' : 'Enable Picture-in-Picture'}
                                    </motion.button>
                                )}
                            </div>

                            {/* Errors and Status */}
                            <div className="mt-4 space-y-4">
                                {pipError && (
                                    <div className="bg-red-50 border border-red-100 rounded-lg p-4">
                                        <div className="flex items-start gap-3">
                                            <AlertCircle className="w-5 h-5 text-red-600 mt-0.5 flex-shrink-0" />
                                            <div>
                                                <h4 className="font-medium text-red-800">Picture-in-Picture Error</h4>
                                                <p className="mt-1 text-sm text-red-600">{pipError}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className="flex items-center justify-between">
                                    <div className="flex items-center gap-4">
                                        <div className="flex items-center gap-2">
                                            <span className={`inline-block w-2 h-2 rounded-full ${isReady ? 'bg-green-500' : 'bg-gray-300'}`}></span>
                                            <span className="text-sm text-gray-600">Ready</span>
                                        </div>
                                        <div className="flex items-center gap-2">
                                            <span className="text-sm text-gray-600">AI Responses:</span>
                                            <span className={`inline-block w-2 h-2 rounded-full ${isReady ? 'bg-green-500' : 'bg-gray-300'}`}></span>
                                            <span className="text-sm text-gray-600">Ready</span>
                                        </div>
                                    </div>

                                    {/* Controls */}
                                    <div className="flex items-center gap-3">
                                        <motion.button
                                            onClick={onCancel}
                                            whileHover={{ scale: 1.02 }}
                                            whileTap={{ scale: 0.98 }}
                                            className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
                                        >
                                            Cancel
                                        </motion.button>
                                        <motion.button
                                            onClick={() => onComplete({
                                                stream: meetingStream.processStream,
                                                displayStream: meetingStream.videoStream,
                                                videoRef,
                                                togglePiP
                                            })}
                                            whileHover={{ scale: 1.02 }}
                                            whileTap={{ scale: 0.98 }}
                                            className="px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors inline-flex items-center gap-2"
                                            disabled={!isReady}
                                        >
                                            Start Interview
                                            <CheckCircle2 className="w-5 h-5" />
                                        </motion.button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Helper Text */}
                    <div className="mt-6 space-y-4">
                        <div className="p-6 bg-gradient-to-br from-blue-50 to-blue-50/50 rounded-xl shadow-sm">
                            <div className="flex items-start gap-4">
                                <AlertCircle className="w-6 h-6 text-blue-600 mt-0.5 flex-shrink-0" />
                                <div className="space-y-6 flex-1">
                                    <div>
                                        <h4 className="text-lg font-medium text-blue-800 flex items-center gap-2">
                                            <span>Important Setup Guide</span>
                                            <span className="text-sm bg-blue-100 px-2 py-0.5 rounded-full">Read Me</span>
                                        </h4>
                                    </div>

                                    {stage === 'select-meeting' ? (
                                        <div className="space-y-6">
                                            <div className="bg-white/50 rounded-lg p-4 border border-blue-100">
                                                <p className="text-sm text-blue-700">
                                                    💡 Select your meeting tab from Google Meet, Zoom, or Microsoft Teams to continue. Make sure to share both audio and video.
                                                </p>
                                            </div>

                                            <div className="space-y-3">
                                                <p className="font-medium text-blue-800 flex items-center gap-2">
                                                    🎯 For Best Results:
                                                </p>
                                                <ul className="grid gap-2 text-sm text-blue-600">
                                                    <li className="flex items-center gap-2">
                                                        <span className="text-lg">🌐</span>
                                                        Use Chrome browser for optimal compatibility
                                                    </li>
                                                    <li className="flex items-center gap-2">
                                                        <span className="text-lg">🎯</span>
                                                        Select the specific tab where your interview is taking place
                                                    </li>
                                                    <li className="flex items-center gap-2">
                                                        <span className="text-lg">🔊</span>
                                                        Enable "Share tab audio" in the sharing dialog
                                                    </li>
                                                    <li className="flex items-center gap-2">
                                                        <span className="text-lg">🍎</span>
                                                        <span>
                                                            <strong className="text-blue-700">MacOS Users:</strong> Interview must be in a Chrome tab, not a separate app
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="bg-white/50 rounded-lg p-4 border border-blue-100">
                                                <p className="font-medium text-blue-800 flex items-center gap-2 mb-2">
                                                    🤔 No Interview Yet?
                                                </p>
                                                <p className="text-sm text-blue-600">
                                                    You can use any video call platform (Zoom, Meet, Teams) or try our mock interview videos for practice! 🎥
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="space-y-4">
                                            <div className="bg-white/50 rounded-lg p-4 border border-blue-100">
                                                <p className="text-sm text-blue-700">
                                                    🎥 Click the pulsating Picture-in-Picture button to enable floating video mode. This will keep your meeting visible while using our platform.
                                                </p>
                                            </div>

                                            <div className="space-y-3">
                                                <p className="font-medium text-blue-800">✨ Quick Tips:</p>
                                                <ul className="grid gap-2 text-sm text-blue-600">
                                                    <li className="flex items-center gap-2">
                                                        <span className="text-lg">🎯</span>
                                                        The floating window can be moved anywhere on your screen
                                                    </li>
                                                    <li className="flex items-center gap-2">
                                                        <span className="text-lg">↩️</span>
                                                        You can exit PiP mode at any time by clicking the button again
                                                    </li>
                                                    <li className="flex items-center gap-2">
                                                        <span className="text-lg">🔄</span>
                                                        If the video disappears, click the PiP button to restore it
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

SessionSetupFlow.propTypes = {
    onComplete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default SessionSetupFlow;

// import React, { useState, useRef, useEffect } from 'react';
// import { motion } from 'framer-motion';
// import { Share2, AlertCircle, Maximize2 } from 'lucide-react';

// const SessionSetupFlow = ({ onComplete, onCancel }) => {
//     const [stage, setStage] = useState('loading');
//     const [isConnecting, setIsConnecting] = useState(true);
//     const [meetingStream, setMeetingStream] = useState(null);
//     const [isPiPActive, setIsPiPActive] = useState(false);
//     const [pipError, setPipError] = useState(null);

//     const videoRef = useRef(null);

//     useEffect(() => {
//         // Check PiP support on mount
//         setPipError(!document.pictureInPictureEnabled ? 'Your browser does not support Picture-in-Picture mode.' : null);

//         // Simulate initial connection
//         setTimeout(() => {
//             setIsConnecting(false);
//             setStage('select-meeting');
//         }, 2000);

//         return () => {
//             cleanupStream();
//         };
//     }, []);

//     const cleanupStream = () => {
//         if (meetingStream) {
//             if (meetingStream.processStream) {
//                 meetingStream.processStream.getTracks().forEach(track => track.stop());
//             }
//             if (meetingStream.videoStream) {
//                 meetingStream.videoStream.getTracks().forEach(track => track.stop());
//             }
//             setMeetingStream(null);
//         }
//     };

//     const handleMeetingSourceSelect = async (sourceType) => {
//         try {
//             // Clear any existing errors
//             setPipError(null);

//             // Cleanup existing stream
//             cleanupStream();

//             console.log('Requesting display media...');
//             const displayMedia = await navigator.mediaDevices.getDisplayMedia({
//                 video: {
//                     width: { ideal: 1920 },
//                     height: { ideal: 1080 },
//                     frameRate: { ideal: 30 }
//                 },
//                 audio: {
//                     suppressLocalAudioPlayback: false,
//                     echoCancellation: true,
//                     noiseSuppression: true,
//                     autoGainControl: true
//                 }
//             });

//             // Verify audio tracks
//             const audioTracks = displayMedia.getAudioTracks();
//             if (audioTracks.length === 0) {
//                 console.warn('⚠️ No audio tracks found in the display media stream');
//                 alert('No audio detected. Please ensure you have selected a tab/window with audio.');
//                 return;
//             }

//             const videoTrack = displayMedia.getVideoTracks()[0];
//             const audioTrack = audioTracks[0];

//             // Create a new MediaStream with cloned tracks for our AI processing
//             const processStream = new MediaStream([
//                 videoTrack.clone(),
//                 audioTrack.clone()
//             ]);

//             // Create a new MediaStream for the video element
//             const videoStream = new MediaStream([
//                 videoTrack,
//                 audioTrack
//             ]);

//             console.log('Setting up streams...');
//             setMeetingStream({
//                 processStream,  // For AI processing
//                 videoStream    // For video display and PiP
//             });

//             console.log('Streams initialized:', {
//                 hasProcessStream: !!processStream,
//                 hasVideoStream: !!videoStream,
//                 videoTracks: videoStream.getVideoTracks().length,
//                 audioTracks: videoStream.getAudioTracks().length
//             });

//             if (videoRef.current) {
//                 videoRef.current.srcObject = videoStream;
//                 videoRef.current.muted = false;
//                 await videoRef.current.play();
//             }

//             const handleTrackEnd = () => {
//                 cleanupStream();
//                 setStage('select-meeting');
//                 setIsPiPActive(false);
//             };

//             videoTrack.onended = handleTrackEnd;
//             audioTrack.onended = handleTrackEnd;

//             setStage('ready');
//         } catch (error) {
//             console.error('Meeting source selection error:', error);
//             if (error.name === 'NotAllowedError') {
//                 alert('Please select your meeting tab/window to continue.');
//             } else if (error.name === 'NotFoundError') {
//                 alert('No audio or video source found. Please ensure your screen has audio.');
//             } else {
//                 alert('Failed to access screen sharing. Please try again.');
//             }
//         }
//     };

//     const togglePiP = async () => {
//         try {
//             // First check if we're exiting PiP
//             if (document.pictureInPictureElement) {
//                 await document.exitPictureInPicture();
//                 setIsPiPActive(false);
//                 return;
//             }

//             // Validate stream existence
//             if (!meetingStream?.videoStream) {
//                 setPipError('Please share your meeting tab first before enabling Picture-in-Picture.');
//                 return;
//             }

//             // Validate video element
//             if (!videoRef.current) {
//                 setPipError('Video element not initialized. Please try refreshing the page.');
//                 return;
//             }

//             // Ensure we have an active stream
//             if (!videoRef.current.srcObject) {
//                 videoRef.current.srcObject = meetingStream.videoStream;
//             }

//             // Ensure video is playing
//             if (videoRef.current.paused) {
//                 try {
//                     await videoRef.current.play();
//                 } catch (playError) {
//                     console.error('Error playing video:', playError);
//                     setPipError('Failed to play video stream. Please ensure you have granted all necessary permissions.');
//                     return;
//                 }
//             }

//             await videoRef.current.requestPictureInPicture();
//             setIsPiPActive(true);
//         } catch (error) {
//             console.error('PiP error:', error);
//             setPipError(error.message || 'Failed to enable Picture-in-Picture. Please try again.');
//         }
//     };

//     // Loading Screen
//     if (stage === 'loading') {
//         return (
//             <div className="fixed inset-0 bg-white flex flex-col items-center justify-center">
//                 <motion.div
//                     animate={{ rotate: 360 }}
//                     transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
//                     className="w-12 h-12 border-4 border-orange-500 border-t-transparent rounded-full"
//                 />
//                 <h2 className="mt-4 text-xl font-semibold">Session loading...</h2>
//                 <p className="mt-2 text-gray-500">
//                     We are <span className="text-orange-500 font-medium">connecting to copilot server</span>
//                 </p>
//             </div>
//         );
//     }

//     return (
//         <div className="min-h-screen bg-white flex flex-col">
//             {/* Header */}
//             <div className="border-b">
//                 <div className="flex items-center justify-between px-4 py-2">
//                     <div className="flex items-center gap-2">
//                         <h1 className="text-lg font-medium">Live Interview</h1>
//                         <span className="px-2 py-0.5 bg-orange-100 text-orange-600 rounded-full text-sm font-medium">
//                             Premium
//                         </span>
//                     </div>
//                     <div className="text-sm text-gray-500">
//                         00:00
//                     </div>
//                 </div>
//             </div>

//             <div className="flex-1 p-4">
//                 <div className="max-w-4xl mx-auto">
//                     {/* Meeting Source Selection */}
//                     {stage === 'select-meeting' && (
//                         <div className="bg-black rounded-lg aspect-video flex flex-col items-center justify-center text-white p-8">
//                             <h2 className="text-xl font-medium mb-6">
//                                 Connect to your interview meeting room (do this 2 times)
//                             </h2>
//                             <button
//                                 onClick={() => handleMeetingSourceSelect('tab')}
//                                 className="flex items-center gap-2 px-6 py-3 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
//                             >
//                                 <Share2 className="w-5 h-5" />
//                                 Select
//                             </button>
//                             <p className="mt-6 text-sm text-gray-400 text-center">
//                                 Once you have selected the interview meeting room<br />
//                                 the transcript will be displayed here.
//                             </p>
//                         </div>
//                     )}

//                     {/* PiP Setup */}
//                     {stage === 'ready' && (
//                         <div>
//                             <div className="relative bg-black rounded-lg overflow-hidden">
//                                 <video
//                                     ref={videoRef}
//                                     autoPlay
//                                     playsInline
//                                     controls
//                                     className="w-full aspect-video"
//                                 />
//                                 {!pipError && (
//                                     <div className="absolute bottom-4 right-4">
//                                         <div className="absolute inset-0 animate-[ping_2s_cubic-bezier(0,0,0.2,1)_infinite] rounded-lg bg-orange-400/50" />
//                                         <button
//                                             onClick={togglePiP}
//                                             className="relative inline-flex items-center gap-2 px-4 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 shadow-lg hover:scale-105 transition-all"
//                                         >
//                                             <Maximize2 className="w-5 h-5" />
//                                             {isPiPActive ? 'Exit Picture-in-Picture' : 'Enable Picture-in-Picture'}
//                                         </button>
//                                     </div>
//                                 )}
//                             </div>

//                             {/* Errors and Status */}
//                             <div className="mt-4 space-y-4">
//                                 {pipError && (
//                                     <div className="bg-red-50 border border-red-100 rounded-lg p-4">
//                                         <div className="flex items-start gap-3">
//                                             <AlertCircle className="w-5 h-5 text-red-600 mt-0.5 flex-shrink-0" />
//                                             <div>
//                                                 <h4 className="font-medium text-red-800">Picture-in-Picture Error</h4>
//                                                 <p className="mt-1 text-sm text-red-600">{pipError}</p>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 )}

//                                 <div className="flex items-center justify-between">
//                                     <div className="flex items-center gap-4">
//                                         <div className="flex items-center gap-2">
//                                             <span className="inline-block w-2 h-2 bg-green-500 rounded-full"></span>
//                                             <span className="text-sm text-gray-600">Ready</span>
//                                         </div>
//                                         <div className="flex items-center gap-2">
//                                             <span className="text-sm text-gray-600">AI Responses:</span>
//                                             <span className="inline-block w-2 h-2 bg-green-500 rounded-full"></span>
//                                             <span className="text-sm text-gray-600">Ready</span>
//                                         </div>
//                                     </div>

//                                     {/* Controls moved to right side */}
//                                     <div className="flex items-center gap-3">
//                                         <button
//                                             onClick={onCancel}
//                                             className="px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors"
//                                         >
//                                             Cancel
//                                         </button>
//                                         <button
//                                             onClick={() => onComplete({
//                                                 stream: meetingStream.processStream,
//                                                 displayStream: meetingStream.videoStream,
//                                                 videoRef,
//                                                 togglePiP
//                                             })}
//                                             className="px-6 py-2 bg-orange-500 text-white rounded-lg hover:bg-orange-600 transition-colors"
//                                         >
//                                             Start Interview
//                                         </button>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     )}

//                     {/* Helper Text */}
//                     <div className="mt-6 p-4 bg-blue-50 rounded-lg">
//                         <div className="flex items-start gap-3">
//                             <AlertCircle className="w-5 h-5 text-blue-600 mt-0.5" />
//                             <div>
//                                 <h4 className="font-medium text-blue-800">Important Note</h4>
//                                 <p className="mt-1 text-sm text-blue-600">
//                                     {stage === 'select-meeting'
//                                         ? 'Select your meeting tab from Google Meet, Zoom, or Microsoft Teams to continue.'
//                                         : 'Use Picture-in-Picture mode to keep your meeting visible while using our platform.'}
//                                 </p>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default SessionSetupFlow;