// import React, { useState, useEffect, useRef } from 'react';
// import { Settings, Bot } from 'lucide-react';
// import SessionSetupFlow from './SessionSetupFlow';

// const LiveInterviewManagement = () => {
//     const [isSetupComplete, setIsSetupComplete] = useState(false);
//     const [interviewSession, setInterviewSession] = useState(null);
//     const [transcription, setTranscription] = useState([]);
//     const [interimTranscript, setInterimTranscript] = useState('');
//     const [aiResponse, setAIResponse] = useState('');
//     const transcriptionRef = useRef(null);
//     const lastProcessedRef = useRef('');

//     // Improved question detection logic
//     const isQuestion = (text) => {
//         const questionWords = ["what", "how", "why", "when", "where", "who", "is", "are", "do", "does", "can", "could", "would", "should"];
//         const questionPhrases = ["tell me", "explain", "describe", "what if", "how about"];

//         // Check if the text ends with a question mark
//         if (text.trim().endsWith("?")) {
//             return true;
//         }

//         // Check if the text starts with a question word
//         const firstWord = text.trim().toLowerCase().split(" ")[0];
//         if (questionWords.includes(firstWord)) {
//             return true;
//         }

//         // Check if the text contains a question phrase
//         if (questionPhrases.some(phrase => text.toLowerCase().includes(phrase))) {
//             return true;
//         }

//         // Check for rising intonation (optional, heuristic-based)
//         const words = text.trim().split(" ");
//         if (words.length > 3 && words[words.length - 1].toLowerCase() === "up") {
//             return true;
//         }

//         console.log(`[DEBUG] Text is not a question: "${text}"`);
//         return false;
//     };

//     // Function to stream OpenAI responses
//     const streamOpenAIResponse = async (question) => {
//         console.log(`[DEBUG] Sending question to OpenAI: "${question}"`);
//         try {
//             const response = await fetch('https://api.openai.com/v1/chat/completions', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer ${process.env.OPENAI_API_KEY}`,
//                 },
//                 body: JSON.stringify({
//                     model: 'gpt-4o-mini',
//                     messages: [
//                         {
//                             role: 'user',
//                             content: question,
//                         },
//                     ],
//                     stream: true, // Enable streaming
//                 }),
//             });

//             const reader = response.body.getReader();
//             let result = '';

//             console.log('[DEBUG] Streaming OpenAI response...');
//             while (true) {
//                 const { done, value } = await reader.read();
//                 if (done) {
//                     console.log('[DEBUG] OpenAI streaming completed.');
//                     break;
//                 }

//                 // Decode the streamed chunk
//                 const chunk = new TextDecoder().decode(value);
//                 const lines = chunk.split('\n').filter(line => line.trim() !== '');

//                 for (const line of lines) {
//                     if (line.startsWith('data: ')) {
//                         const data = line.replace('data: ', '');
//                         if (data === '[DONE]') {
//                             console.log('[DEBUG] OpenAI streaming finished.');
//                             break;
//                         }

//                         console.log(`[DEBUG] Raw data from OpenAI: "${data}"`);
//                         try {
//                             const json = JSON.parse(data);
//                             const token = json.choices[0]?.delta?.content || '';
//                             result += token;

//                             console.log(`[DEBUG] Received token from OpenAI: "${token}"`);
//                             console.log(`[DEBUG] Current AI response: "${result}"`);

//                             // Update the UI with the streaming response
//                             setAIResponse((prev) => prev + token);
//                         } catch (error) {
//                             console.error('[ERROR] Failed to parse OpenAI response:', error);
//                         }
//                     }
//                 }
//             }
//         } catch (error) {
//             console.error('[ERROR] Error streaming OpenAI response:', error);
//             setAIResponse('Failed to stream response.');
//         }
//     };

//     // Initialize speech recognition when the session is set up
//     useEffect(() => {
//         let recognition = null;

//         const setupTranscription = () => {
//             if (!interviewSession?.stream) {
//                 console.log('[DEBUG] No interview session stream found.');
//                 return;
//             }

//             // Create audio context and source
//             const audioContext = new AudioContext();
//             const source = audioContext.createMediaStreamSource(interviewSession.stream);

//             // Create speech recognition
//             const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
//             if (!SpeechRecognition) {
//                 console.error('[ERROR] Speech recognition not supported');
//                 return;
//             }

//             recognition = new SpeechRecognition();
//             recognition.continuous = true;
//             recognition.interimResults = true;
//             recognition.lang = 'en-US';

//             recognition.onresult = (event) => {
//                 let interimResult = '';
//                 let finalResult = '';

//                 console.log('[DEBUG] Processing speech recognition results...');
//                 for (let i = event.resultIndex; i < event.results.length; i++) {
//                     const transcript = event.results[i][0].transcript;
//                     if (event.results[i].isFinal) {
//                         // Only add if it's not a duplicate
//                         if (transcript !== lastProcessedRef.current) {
//                             finalResult = transcript;
//                             lastProcessedRef.current = transcript;
//                             console.log(`[DEBUG] Final transcription: "${finalResult}"`);
//                         }
//                     } else {
//                         interimResult += transcript;
//                         console.log(`[DEBUG] Interim transcription: "${interimResult}"`);
//                     }
//                 }

//                 // Update interim transcript
//                 setInterimTranscript(interimResult);

//                 // Add final result to transcription if we have one
//                 if (finalResult) {
//                     const newEntry = {
//                         id: Date.now(),
//                         text: finalResult.trim(),
//                         timestamp: new Date().toISOString(),
//                     };
//                     console.log(`[DEBUG] Adding final transcription to list: "${finalResult}"`);
//                     setTranscription((prev) => [...prev, newEntry]);

//                     // Auto scroll to latest
//                     if (transcriptionRef.current) {
//                         transcriptionRef.current.scrollTop = transcriptionRef.current.scrollHeight;
//                     }

//                     // Check if the transcription is a question
//                     if (isQuestion(finalResult)) {
//                         console.log(`[DEBUG] Detected question: "${finalResult}"`);
//                         // Clear previous response and start streaming
//                         setAIResponse('');
//                         streamOpenAIResponse(finalResult);
//                     } else {
//                         console.log(`[DEBUG] Not a question: "${finalResult}"`);
//                     }
//                 }
//             };

//             recognition.onerror = (event) => {
//                 console.error('[ERROR] Speech recognition error:', event.error);
//             };

//             // Connect audio to the context
//             const gainNode = audioContext.createGain();
//             source.connect(gainNode);
//             gainNode.connect(audioContext.destination);

//             // Start recognition
//             console.log('[DEBUG] Starting speech recognition...');
//             recognition.start();
//         };

//         if (isSetupComplete && interviewSession) {
//             console.log('[DEBUG] Setting up transcription...');
//             setupTranscription();
//         }

//         return () => {
//             if (recognition) {
//                 console.log('[DEBUG] Stopping speech recognition...');
//                 recognition.stop();
//             }
//         };
//     }, [isSetupComplete, interviewSession]);

//     const handleSetupComplete = (sessionData) => {
//         console.log('[DEBUG] Interview setup complete:', sessionData);
//         setInterviewSession(sessionData);
//         setIsSetupComplete(true);
//     };

//     const handleLeaveInterview = () => {
//         console.log('[DEBUG] Leaving interview...');
//         if (interviewSession) {
//             if (interviewSession.stream) {
//                 interviewSession.stream.getTracks().forEach(track => track.stop());
//             }
//             if (interviewSession.displayStream) {
//                 interviewSession.displayStream.getTracks().forEach(track => track.stop());
//             }
//         }
//         setIsSetupComplete(false);
//         setInterviewSession(null);
//         setTranscription([]);
//         setAIResponse('');
//     };

//     if (!isSetupComplete) {
//         return <SessionSetupFlow onComplete={handleSetupComplete} />;
//     }

//     return (
//         <div className="min-h-screen bg-gray-50">
//             {/* Header */}
//             <div className="border-b bg-white">
//                 <div className="max-w-7xl mx-auto px-4 py-3 flex items-center justify-between">
//                     <div className="flex items-center gap-3">
//                         <h1 className="text-xl font-semibold">Live Interview</h1>
//                         <span className="px-2 py-1 bg-orange-500 text-white text-sm rounded-full">
//                             Premium
//                         </span>
//                         <div className="flex items-center gap-1 bg-secondary/20 px-3 py-1 rounded-full">
//                             <span className="font-medium">00:00</span>
//                         </div>
//                     </div>
//                     <div className="flex items-center gap-4">
//                         <button className="p-2 hover:bg-gray-100 rounded-full">
//                             <Settings className="w-5 h-5" />
//                         </button>
//                         <button
//                             onClick={handleLeaveInterview}
//                             className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
//                         >
//                             Leave Interview
//                         </button>
//                     </div>
//                 </div>
//             </div>

//             {/* Main Content */}
//             <div className="max-w-7xl mx-auto p-4">
//                 <div className="grid grid-cols-2 gap-4">
//                     {/* Left Column - Video and Transcription */}
//                     <div className="space-y-4">
//                         {/* Video Feed */}
//                         <div className="relative">
//                             <video
//                                 ref={interviewSession?.videoRef}
//                                 autoPlay
//                                 playsInline
//                                 controls
//                                 className="w-full rounded-lg bg-black aspect-video"
//                             />
//                             <button
//                                 onClick={interviewSession?.togglePiP}
//                                 className="absolute bottom-4 right-4 px-3 py-1.5 bg-black/50 text-white rounded-lg hover:bg-black/70"
//                             >
//                                 PiP View
//                             </button>
//                         </div>

//                         {/* Transcription */}
//                         <div className="bg-white rounded-lg shadow-sm p-4">
//                             <div className="flex items-center justify-between mb-4">
//                                 <h2 className="font-medium">Live Transcription</h2>
//                                 <span className="flex items-center gap-1 text-green-600 text-sm">
//                                     <span className="w-2 h-2 bg-green-500 rounded-full"></span>
//                                     Active
//                                 </span>
//                             </div>
//                             <div
//                                 ref={transcriptionRef}
//                                 className="h-48 overflow-y-auto space-y-2 text-sm"
//                             >
//                                 {transcription.map((entry) => (
//                                     <div key={entry.id} className="p-2 rounded bg-gray-50">
//                                         <p>{entry.text}</p>
//                                         <span className="text-xs text-gray-500">
//                                             {new Date(entry.timestamp).toLocaleTimeString()}
//                                         </span>
//                                     </div>
//                                 ))}
//                                 {interimTranscript && (
//                                     <div className="p-2 rounded bg-gray-100 text-gray-500 italic">
//                                         {interimTranscript}
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//                     </div>

//                     {/* Right Column - AI Copilot */}
//                     <div className="bg-white rounded-lg shadow-sm">
//                         <div className="p-4 border-b">
//                             <div className="flex items-center justify-between">
//                                 <div className="flex items-center gap-2">
//                                     <Bot className="w-5 h-5" />
//                                     <h2 className="font-medium">Interview Copilot™</h2>
//                                 </div>
//                                 <span className="flex items-center gap-1 text-green-600 text-sm">
//                                     <span className="w-2 h-2 bg-green-500 rounded-full"></span>
//                                     Ready
//                                 </span>
//                             </div>
//                         </div>
//                         <div className="p-4 h-[calc(100vh-16rem)] overflow-y-auto">
//                             {aiResponse ? (
//                                 <div className="text-gray-700 whitespace-pre-wrap">{aiResponse}</div>
//                             ) : (
//                                 <div className="text-center text-gray-500 mt-8">
//                                     Waiting for interview to begin...
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default LiveInterviewManagement;

// import React, { useState, useEffect, useRef } from 'react';
// import { Settings, Bot } from 'lucide-react';
// import SessionSetupFlow from './SessionSetupFlow';

// const LiveInterviewManagement = () => {
//     const [isSetupComplete, setIsSetupComplete] = useState(false);
//     const [interviewSession, setInterviewSession] = useState(null);
//     const [transcription, setTranscription] = useState([]);
//     const [interimTranscript, setInterimTranscript] = useState('');
//     const [aiResponse, setAIResponse] = useState('');
//     const transcriptionRef = useRef(null);
//     const lastProcessedRef = useRef('');
//     const audioContextRef = useRef(null);
//     const mediaStreamRef = useRef(null);
//     const recognitionRef = useRef(null);

//     // Function to detect if a transcription is a question
//     const isQuestion = (text) => {
//         const questionWords = ["what", "whats the", "how", "why", "when", "where", "who", "is", "are", "do", "does", "can", "could", "would", "should"];
//         const questionPhrases = ["tell me", "explain", "describe", "what if", "how about"];

//         // Check if the text ends with a question mark
//         if (text.trim().endsWith("?")) {
//             return true;
//         }

//         // Check if the text starts with a question word
//         const firstWord = text.trim().toLowerCase().split(" ")[0];
//         if (questionWords.includes(firstWord)) {
//             return true;
//         }

//         // Check if the text contains a question phrase
//         if (questionPhrases.some(phrase => text.toLowerCase().includes(phrase))) {
//             return true;
//         }

//         // Check for rising intonation (optional, heuristic-based)
//         const words = text.trim().split(" ");
//         if (words.length > 3 && words[words.length - 1].toLowerCase() === "up") {
//             return true;
//         }

//         console.log(`[DEBUG] Text is not a question: "${text}"`);
//         return false;
//     };

//     // Function to stream OpenAI responses
//     const streamOpenAIResponse = async (question) => {
//         console.log(`[DEBUG] Sending question to OpenAI: "${question}"`);
//         try {
//             const response = await fetch('https://api.openai.com/v1/chat/completions', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer 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`,
//                 },
//                 body: JSON.stringify({
//                     model: 'gpt-4o',
//                     messages: [
//                         {
//                             role: 'user',
//                             content: question,
//                         },
//                     ],
//                     stream: true, // Enable streaming
//                 }),
//             });

//             const reader = response.body.getReader();
//             let result = '';

//             console.log('[DEBUG] Streaming OpenAI response...');
//             while (true) {
//                 const { done, value } = await reader.read();
//                 if (done) {
//                     console.log('[DEBUG] OpenAI streaming completed.');
//                     break;
//                 }

//                 // Decode the streamed chunk
//                 const chunk = new TextDecoder().decode(value);
//                 const lines = chunk.split('\n').filter(line => line.trim() !== '');

//                 for (const line of lines) {
//                     if (line.startsWith('data: ')) {
//                         const data = line.replace('data: ', '');
//                         if (data === '[DONE]') {
//                             console.log('[DEBUG] OpenAI streaming finished.');
//                             break;
//                         }

//                         console.log(`[DEBUG] Raw data from OpenAI: "${data}"`);
//                         try {
//                             const json = JSON.parse(data);
//                             const token = json.choices[0]?.delta?.content || '';
//                             result += token;

//                             console.log(`[DEBUG] Received token from OpenAI: "${token}"`);
//                             console.log(`[DEBUG] Current AI response: "${result}"`);

//                             // Update the UI with the streaming response
//                             setAIResponse((prev) => prev + token);
//                         } catch (error) {
//                             console.error('[ERROR] Failed to parse OpenAI response:', error);
//                         }
//                     }
//                 }
//             }
//         } catch (error) {
//             console.error('[ERROR] Error streaming OpenAI response:', error);
//             setAIResponse('Failed to stream response.');
//         }
//     };

//     // Initialize speech recognition when the session is set up
//     useEffect(() => {
//         if (!isSetupComplete || !interviewSession) return;

//         const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
//         if (!SpeechRecognition) {
//             console.error('[ERROR] Speech recognition not supported');
//             return;
//         }

//         recognitionRef.current = new SpeechRecognition();
//         recognitionRef.current.continuous = true;
//         recognitionRef.current.interimResults = true;
//         recognitionRef.current.lang = 'en-US';

//         recognitionRef.current.onresult = (event) => {
//             let interimResult = '';
//             let finalResult = '';

//             console.log('[DEBUG] Processing speech recognition results...');
//             for (let i = event.resultIndex; i < event.results.length; i++) {
//                 const transcript = event.results[i][0].transcript;
//                 if (event.results[i].isFinal) {
//                     // Only add if it's not a duplicate
//                     if (transcript !== lastProcessedRef.current) {
//                         finalResult = transcript;
//                         lastProcessedRef.current = transcript;
//                         console.log(`[DEBUG] Final transcription: "${finalResult}"`);
//                     }
//                 } else {
//                     interimResult += transcript;
//                     console.log(`[DEBUG] Interim transcription: "${interimResult}"`);
//                 }
//             }

//             // Update interim transcript
//             setInterimTranscript(interimResult);

//             // Add final result to transcription if we have one
//             if (finalResult) {
//                 const newEntry = {
//                     id: Date.now(),
//                     text: finalResult.trim(),
//                     timestamp: new Date().toISOString(),
//                 };
//                 console.log(`[DEBUG] Adding final transcription to list: "${finalResult}"`);
//                 setTranscription((prev) => [...prev, newEntry]);

//                 // Auto scroll to latest
//                 if (transcriptionRef.current) {
//                     transcriptionRef.current.scrollTop = transcriptionRef.current.scrollHeight;
//                 }

//                 // Check if the transcription is a question
//                 if (isQuestion(finalResult)) {
//                     console.log(`[DEBUG] Detected question: "${finalResult}"`);
//                     // Clear previous response and start streaming
//                     setAIResponse('');
//                     streamOpenAIResponse(finalResult);
//                 } else {
//                     console.log(`[DEBUG] Not a question: "${finalResult}"`);
//                 }
//             }
//         };

//         recognitionRef.current.onend = () => {
//             console.log('[DEBUG] Speech recognition ended. Restarting...');
//             recognitionRef.current.start();
//         };

//         recognitionRef.current.start();

//         return () => {
//             if (recognitionRef.current) {
//                 recognitionRef.current.stop();
//             }
//         };
//     }, [isSetupComplete, interviewSession]);

//     // Initialize audio context and media stream
//     useEffect(() => {
//         if (!isSetupComplete || !interviewSession?.stream) return;

//         audioContextRef.current = new AudioContext();
//         mediaStreamRef.current = interviewSession.stream;
//         const source = audioContextRef.current.createMediaStreamSource(mediaStreamRef.current);
//         const gainNode = audioContextRef.current.createGain();
//         source.connect(gainNode);
//         gainNode.connect(audioContextRef.current.destination);

//         return () => {
//             if (audioContextRef.current) {
//                 audioContextRef.current.close();
//             }
//             if (mediaStreamRef.current) {
//                 mediaStreamRef.current.getTracks().forEach(track => track.stop());
//             }
//         };
//     }, [isSetupComplete, interviewSession]);

//     const handleSetupComplete = (sessionData) => {
//         console.log('[DEBUG] Interview setup complete:', sessionData);
//         setInterviewSession(sessionData);
//         setIsSetupComplete(true);
//     };

//     const handleLeaveInterview = () => {
//         console.log('[DEBUG] Leaving interview...');
//         if (interviewSession) {
//             if (interviewSession.stream) {
//                 interviewSession.stream.getTracks().forEach(track => track.stop());
//             }
//             if (interviewSession.displayStream) {
//                 interviewSession.displayStream.getTracks().forEach(track => track.stop());
//             }
//         }
//         setIsSetupComplete(false);
//         setInterviewSession(null);
//         setTranscription([]);
//         setAIResponse('');
//     };

//     if (!isSetupComplete) {
//         return <SessionSetupFlow onComplete={handleSetupComplete} />;
//     }

//     return (
//         <div className="min-h-screen bg-gray-50">
//             {/* Header */}
//             <div className="border-b bg-white">
//                 <div className="max-w-7xl mx-auto px-4 py-3 flex items-center justify-between">
//                     <div className="flex items-center gap-3">
//                         <h1 className="text-xl font-semibold">Live Interview</h1>
//                         <span className="px-2 py-1 bg-orange-500 text-white text-sm rounded-full">
//                             Premium
//                         </span>
//                         <div className="flex items-center gap-1 bg-secondary/20 px-3 py-1 rounded-full">
//                             <span className="font-medium">00:00</span>
//                         </div>
//                     </div>
//                     <div className="flex items-center gap-4">
//                         <button className="p-2 hover:bg-gray-100 rounded-full">
//                             <Settings className="w-5 h-5" />
//                         </button>
//                         <button
//                             onClick={handleLeaveInterview}
//                             className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
//                         >
//                             Leave Interview
//                         </button>
//                     </div>
//                 </div>
//             </div>

//             {/* Main Content */}
//             <div className="max-w-7xl mx-auto p-4">
//                 <div className="grid grid-cols-2 gap-4">
//                     {/* Left Column - Video and Transcription */}
//                     <div className="space-y-4">
//                         {/* Video Feed */}
//                         <div className="relative">
//                             <video
//                                 ref={interviewSession?.videoRef}
//                                 autoPlay
//                                 playsInline
//                                 controls
//                                 className="w-full rounded-lg bg-black aspect-video"
//                             />
//                             <button
//                                 onClick={interviewSession?.togglePiP}
//                                 className="absolute bottom-4 right-4 px-3 py-1.5 bg-black/50 text-white rounded-lg hover:bg-black/70"
//                             >
//                                 PiP View
//                             </button>
//                         </div>

//                         {/* Transcription */}
//                         <div className="bg-white rounded-lg shadow-sm p-4">
//                             <div className="flex items-center justify-between mb-4">
//                                 <h2 className="font-medium">Live Transcription</h2>
//                                 <span className="flex items-center gap-1 text-green-600 text-sm">
//                                     <span className="w-2 h-2 bg-green-500 rounded-full"></span>
//                                     Active
//                                 </span>
//                             </div>
//                             <div
//                                 ref={transcriptionRef}
//                                 className="h-48 overflow-y-auto space-y-2 text-sm"
//                             >
//                                 {transcription.map((entry) => (
//                                     <div key={entry.id} className="p-2 rounded bg-gray-50">
//                                         <p>{entry.text}</p>
//                                         <span className="text-xs text-gray-500">
//                                             {new Date(entry.timestamp).toLocaleTimeString()}
//                                         </span>
//                                     </div>
//                                 ))}
//                                 {interimTranscript && (
//                                     <div className="p-2 rounded bg-gray-100 text-gray-500 italic">
//                                         {interimTranscript}
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//                     </div>

//                     {/* Right Column - AI Copilot */}
//                     <div className="bg-white rounded-lg shadow-sm">
//                         <div className="p-4 border-b">
//                             <div className="flex items-center justify-between">
//                                 <div className="flex items-center gap-2">
//                                     <Bot className="w-5 h-5" />
//                                     <h2 className="font-medium">Interview Copilot™</h2>
//                                 </div>
//                                 <span className="flex items-center gap-1 text-green-600 text-sm">
//                                     <span className="w-2 h-2 bg-green-500 rounded-full"></span>
//                                     Ready
//                                 </span>
//                             </div>
//                         </div>
//                         <div className="p-4 h-[calc(100vh-16rem)] overflow-y-auto">
//                             {aiResponse ? (
//                                 <div className="text-gray-700 whitespace-pre-wrap">{aiResponse}</div>
//                             ) : (
//                                 <div className="text-center text-gray-500 mt-8">
//                                     Waiting for interview to begin...
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default LiveInterviewManagement;

// import React, { useState, useEffect, useRef } from 'react';
// import { Settings, Bot } from 'lucide-react';
// import SessionSetupFlow from './SessionSetupFlow';

// const LiveInterviewManagement = () => {
//     const [isSetupComplete, setIsSetupComplete] = useState(false);
//     const [interviewSession, setInterviewSession] = useState(null);
//     const [transcription, setTranscription] = useState([]);
//     const [interimTranscript, setInterimTranscript] = useState('');
//     const [aiResponse, setAIResponse] = useState('');
//     const transcriptionRef = useRef(null);
//     const lastProcessedRef = useRef('');
//     const audioContextRef = useRef(null);
//     const mediaStreamRef = useRef(null);
//     const recognitionRef = useRef(null);

//     // Function to detect if a transcription is a question
//     const isQuestion = (text) => {
//         const questionWords = ["what", "whats the", "how", "why", "when", "where", "who", "is", "are", "do", "does", "can", "could", "would", "should"];
//         const questionPhrases = ["tell me", "explain", "describe", "what if", "how about"];

//         // Check if the text ends with a question mark
//         if (text.trim().endsWith("?")) {
//             return true;
//         }

//         // Check if the text starts with a question word
//         const firstWord = text.trim().toLowerCase().split(" ")[0];
//         if (questionWords.includes(firstWord)) {
//             return true;
//         }

//         // Check if the text contains a question phrase
//         if (questionPhrases.some(phrase => text.toLowerCase().includes(phrase))) {
//             return true;
//         }

//         // Check for rising intonation (optional, heuristic-based)
//         const words = text.trim().split(" ");
//         if (words.length > 3 && words[words.length - 1].toLowerCase() === "up") {
//             return true;
//         }

//         console.log(`[DEBUG] Text is not a question: "${text}"`);
//         return false;
//     };

//     // Function to stream OpenAI responses
//     const streamOpenAIResponse = async (question) => {
//         console.log(`[DEBUG] Sending question to OpenAI: "${question}"`);
//         try {
//             const response = await fetch('https://api.openai.com/v1/chat/completions', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer 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`,
//                 },
//                 body: JSON.stringify({
//                     model: 'gpt-4o',
//                     messages: [
//                         {
//                             role: 'user',
//                             content: question,
//                         },
//                     ],
//                     stream: true, // Enable streaming
//                 }),
//             });

//             const reader = response.body.getReader();
//             let result = '';

//             console.log('[DEBUG] Streaming OpenAI response...');
//             while (true) {
//                 const { done, value } = await reader.read();
//                 if (done) {
//                     console.log('[DEBUG] OpenAI streaming completed.');
//                     break;
//                 }

//                 // Decode the streamed chunk
//                 const chunk = new TextDecoder().decode(value);
//                 const lines = chunk.split('\n').filter(line => line.trim() !== '');

//                 for (const line of lines) {
//                     if (line.startsWith('data: ')) {
//                         const data = line.replace('data: ', '');
//                         if (data === '[DONE]') {
//                             console.log('[DEBUG] OpenAI streaming finished.');
//                             break;
//                         }

//                         console.log(`[DEBUG] Raw data from OpenAI: "${data}"`);
//                         try {
//                             const json = JSON.parse(data);
//                             const token = json.choices[0]?.delta?.content || '';
//                             result += token;

//                             console.log(`[DEBUG] Received token from OpenAI: "${token}"`);
//                             console.log(`[DEBUG] Current AI response: "${result}"`);

//                             // Update the UI with the streaming response
//                             setAIResponse((prev) => prev + token);
//                         } catch (error) {
//                             console.error('[ERROR] Failed to parse OpenAI response:', error);
//                         }
//                     }
//                 }
//             }
//         } catch (error) {
//             console.error('[ERROR] Error streaming OpenAI response:', error);
//             setAIResponse('Failed to stream response.');
//         }
//     };

//     // Initialize speech recognition when the session is set up
//     useEffect(() => {
//         if (!isSetupComplete || !interviewSession) return;

//         const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
//         if (!SpeechRecognition) {
//             console.error('[ERROR] Speech recognition not supported');
//             return;
//         }

//         recognitionRef.current = new SpeechRecognition();
//         recognitionRef.current.continuous = true;
//         recognitionRef.current.interimResults = true;
//         recognitionRef.current.lang = 'en-US';

//         recognitionRef.current.onresult = (event) => {
//             let interimResult = '';
//             let finalResult = '';

//             console.log('[DEBUG] Processing speech recognition results...');
//             for (let i = event.resultIndex; i < event.results.length; i++) {
//                 const transcript = event.results[i][0].transcript;
//                 if (event.results[i].isFinal) {
//                     // Only add if it's not a duplicate
//                     if (transcript !== lastProcessedRef.current) {
//                         finalResult = transcript;
//                         lastProcessedRef.current = transcript;
//                         console.log(`[DEBUG] Final transcription: "${finalResult}"`);
//                     }
//                 } else {
//                     interimResult += transcript;
//                     console.log(`[DEBUG] Interim transcription: "${interimResult}"`);
//                 }
//             }

//             // Update interim transcript
//             setInterimTranscript(interimResult);

//             // Add final result to transcription if we have one
//             if (finalResult) {
//                 const newEntry = {
//                     id: Date.now(),
//                     text: finalResult.trim(),
//                     timestamp: new Date().toISOString(),
//                 };
//                 console.log(`[DEBUG] Adding final transcription to list: "${finalResult}"`);
//                 setTranscription((prev) => [...prev, newEntry]);

//                 // Auto scroll to latest
//                 if (transcriptionRef.current) {
//                     transcriptionRef.current.scrollTop = transcriptionRef.current.scrollHeight;
//                 }

//                 // Check if the transcription is a question
//                 if (isQuestion(finalResult)) {
//                     console.log(`[DEBUG] Detected question: "${finalResult}"`);
//                     // Clear previous response and start streaming
//                     setAIResponse('');
//                     streamOpenAIResponse(finalResult);
//                 } else {
//                     console.log(`[DEBUG] Not a question: "${finalResult}"`);
//                 }
//             }
//         };

//         recognitionRef.current.onend = () => {
//             console.log('[DEBUG] Speech recognition ended. Restarting...');
//             recognitionRef.current.start();
//         };

//         recognitionRef.current.start();

//         return () => {
//             if (recognitionRef.current) {
//                 recognitionRef.current.stop();
//             }
//         };
//     }, [isSetupComplete, interviewSession]);

//     // Initialize audio context and media stream
//     useEffect(() => {
//         if (!isSetupComplete || !interviewSession?.stream) return;

//         audioContextRef.current = new AudioContext();
//         mediaStreamRef.current = interviewSession.stream;
//         const source = audioContextRef.current.createMediaStreamSource(mediaStreamRef.current);
//         const gainNode = audioContextRef.current.createGain();
//         source.connect(gainNode);
//         gainNode.connect(audioContextRef.current.destination);

//         return () => {
//             if (audioContextRef.current) {
//                 audioContextRef.current.close();
//             }
//             if (mediaStreamRef.current) {
//                 mediaStreamRef.current.getTracks().forEach(track => track.stop());
//             }
//         };
//     }, [isSetupComplete, interviewSession]);

//     const handleSetupComplete = (sessionData) => {
//         console.log('[DEBUG] Interview setup complete:', sessionData);
//         setInterviewSession(sessionData);
//         setIsSetupComplete(true);
//     };

//     const handleLeaveInterview = () => {
//         console.log('[DEBUG] Leaving interview...');
//         if (interviewSession) {
//             if (interviewSession.stream) {
//                 interviewSession.stream.getTracks().forEach(track => track.stop());
//             }
//             if (interviewSession.displayStream) {
//                 interviewSession.displayStream.getTracks().forEach(track => track.stop());
//             }
//         }
//         setIsSetupComplete(false);
//         setInterviewSession(null);
//         setTranscription([]);
//         setAIResponse('');
//     };

//     if (!isSetupComplete) {
//         return <SessionSetupFlow onComplete={handleSetupComplete} />;
//     }

//     return (
//         <div className="min-h-screen bg-gray-50">
//             {/* Header */}
//             <div className="border-b bg-white">
//                 <div className="max-w-7xl mx-auto px-4 py-3 flex items-center justify-between">
//                     <div className="flex items-center gap-3">
//                         <h1 className="text-xl font-semibold">Live Interview</h1>
//                         <span className="px-2 py-1 bg-orange-500 text-white text-sm rounded-full">
//                             Premium
//                         </span>
//                         <div className="flex items-center gap-1 bg-secondary/20 px-3 py-1 rounded-full">
//                             <span className="font-medium">00:00</span>
//                         </div>
//                     </div>
//                     <div className="flex items-center gap-4">
//                         <button className="p-2 hover:bg-gray-100 rounded-full">
//                             <Settings className="w-5 h-5" />
//                         </button>
//                         <button
//                             onClick={handleLeaveInterview}
//                             className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
//                         >
//                             Leave Interview
//                         </button>
//                     </div>
//                 </div>
//             </div>

//             {/* Main Content */}
//             <div className="max-w-7xl mx-auto p-4">
//                 <div className="grid grid-cols-2 gap-4">
//                     {/* Left Column - Video and Transcription */}
//                     <div className="space-y-4">
//                         {/* Video Feed */}
//                         <div className="relative">
//                             <video
//                                 ref={interviewSession?.videoRef}
//                                 autoPlay
//                                 playsInline
//                                 controls
//                                 className="w-full rounded-lg bg-black aspect-video"
//                             />
//                             <button
//                                 onClick={interviewSession?.togglePiP}
//                                 className="absolute bottom-4 right-4 px-3 py-1.5 bg-black/50 text-white rounded-lg hover:bg-black/70"
//                             >
//                                 PiP View
//                             </button>
//                         </div>

//                         {/* Transcription */}
//                         <div className="bg-white rounded-lg shadow-sm p-4">
//                             <div className="flex items-center justify-between mb-4">
//                                 <h2 className="font-medium">Live Transcription</h2>
//                                 <span className="flex items-center gap-1 text-green-600 text-sm">
//                                     <span className="w-2 h-2 bg-green-500 rounded-full"></span>
//                                     Active
//                                 </span>
//                             </div>
//                             <div
//                                 ref={transcriptionRef}
//                                 className="h-48 overflow-y-auto space-y-2 text-sm"
//                             >
//                                 {transcription.map((entry) => (
//                                     <div key={entry.id} className="p-2 rounded bg-gray-50">
//                                         <p>{entry.text}</p>
//                                         <span className="text-xs text-gray-500">
//                                             {new Date(entry.timestamp).toLocaleTimeString()}
//                                         </span>
//                                     </div>
//                                 ))}
//                                 {interimTranscript && (
//                                     <div className="p-2 rounded bg-gray-100 text-gray-500 italic">
//                                         {interimTranscript}
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//                     </div>

//                     {/* Right Column - AI Copilot */}
//                     <div className="bg-white rounded-lg shadow-sm">
//                         <div className="p-4 border-b">
//                             <div className="flex items-center justify-between">
//                                 <div className="flex items-center gap-2">
//                                     <Bot className="w-5 h-5" />
//                                     <h2 className="font-medium">Interview Copilot™</h2>
//                                 </div>
//                                 <span className="flex items-center gap-1 text-green-600 text-sm">
//                                     <span className="w-2 h-2 bg-green-500 rounded-full"></span>
//                                     Ready
//                                 </span>
//                             </div>
//                         </div>
//                         <div className="p-4 h-[calc(100vh-16rem)] overflow-y-auto">
//                             {aiResponse ? (
//                                 <div className="text-gray-700 whitespace-pre-wrap">{aiResponse}</div>
//                             ) : (
//                                 <div className="text-center text-gray-500 mt-8">
//                                     Waiting for interview to begin...
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default LiveInterviewManagement;

import React, { useState, useEffect, useRef } from 'react';
import { Settings, Bot } from 'lucide-react';
import SessionSetupFlow from './SessionSetupFlow';

const LiveInterviewManagement = () => {
    const [isSetupComplete, setIsSetupComplete] = useState(false);
    const [interviewSession, setInterviewSession] = useState(null);
    const [transcription, setTranscription] = useState([]);
    const [interimTranscript, setInterimTranscript] = useState('');
    const [aiResponse, setAIResponse] = useState('');
    const transcriptionRef = useRef(null);
    const lastProcessedRef = useRef('');
    const audioContextRef = useRef(null);
    const mediaStreamRef = useRef(null);
    const recognitionRef = useRef(null);

    // Function to detect if a transcription is a question
    const isQuestion = (text) => {
        const questionWords = ["what", "whats the", "how", "why", "when", "where", "who", "is", "are", "do", "does", "can", "could", "would", "should"];
        const questionPhrases = ["tell me", "explain", "describe", "what if", "how about"];

        // Check if the text ends with a question mark
        if (text.trim().endsWith("?")) {
            return true;
        }

        // Check if the text starts with a question word
        const firstWord = text.trim().toLowerCase().split(" ")[0];
        if (questionWords.includes(firstWord)) {
            return true;
        }

        // Check if the text contains a question phrase
        if (questionPhrases.some(phrase => text.toLowerCase().includes(phrase))) {
            return true;
        }

        // Check for rising intonation (optional, heuristic-based)
        const words = text.trim().split(" ");
        if (words.length > 3 && words[words.length - 1].toLowerCase() === "up") {
            return true;
        }

        console.log(`[DEBUG] Text is not a question: "${text}"`);
        return false;
    };

    // Function to stream OpenAI responses
    const streamOpenAIResponse = async (question) => {
        console.log(`[DEBUG] Sending question to OpenAI: "${question}"`);
        try {
            const response = await fetch('https://api.openai.com/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer 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`,
                },
                body: JSON.stringify({
                    model: 'gpt-4o',
                    messages: [
                        {
                            role: 'user',
                            content: question,
                        },
                    ],
                    stream: true, // Enable streaming
                }),
            });

            const reader = response.body.getReader();
            let result = '';

            console.log('[DEBUG] Streaming OpenAI response...');
            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    console.log('[DEBUG] OpenAI streaming completed.');
                    break;
                }

                // Decode the streamed chunk
                const chunk = new TextDecoder().decode(value);
                const lines = chunk.split('\n').filter(line => line.trim() !== '');

                for (const line of lines) {
                    if (line.startsWith('data: ')) {
                        const data = line.replace('data: ', '');
                        if (data === '[DONE]') {
                            console.log('[DEBUG] OpenAI streaming finished.');
                            break;
                        }

                        console.log(`[DEBUG] Raw data from OpenAI: "${data}"`);
                        try {
                            const json = JSON.parse(data);
                            const token = json.choices[0]?.delta?.content || '';
                            result += token;

                            console.log(`[DEBUG] Received token from OpenAI: "${token}"`);
                            console.log(`[DEBUG] Current AI response: "${result}"`);

                            // Update the UI with the streaming response
                            setAIResponse((prev) => prev + token);
                        } catch (error) {
                            console.error('[ERROR] Failed to parse OpenAI response:', error);
                        }
                    }
                }
            }
        } catch (error) {
            console.error('[ERROR] Error streaming OpenAI response:', error);
            setAIResponse('Failed to stream response.');
        }
    };

    // Initialize speech recognition when the session is set up
    useEffect(() => {
        if (!isSetupComplete || !interviewSession) return;

        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) {
            console.error('[ERROR] Speech recognition not supported');
            return;
        }

        recognitionRef.current = new SpeechRecognition();
        recognitionRef.current.continuous = true;
        recognitionRef.current.interimResults = true;
        recognitionRef.current.lang = 'en-US';

        recognitionRef.current.onresult = (event) => {
            let interimResult = '';
            let finalResult = '';

            console.log('[DEBUG] Processing speech recognition results...');
            for (let i = event.resultIndex; i < event.results.length; i++) {
                const transcript = event.results[i][0].transcript;
                if (event.results[i].isFinal) {
                    // Only add if it's not a duplicate
                    if (transcript !== lastProcessedRef.current) {
                        finalResult = transcript;
                        lastProcessedRef.current = transcript;
                        console.log(`[DEBUG] Final transcription: "${finalResult}"`);
                    }
                } else {
                    interimResult += transcript;
                    console.log(`[DEBUG] Interim transcription: "${interimResult}"`);
                }
            }

            // Update interim transcript
            setInterimTranscript(interimResult);

            // Add final result to transcription if we have one
            if (finalResult) {
                const newEntry = {
                    id: Date.now(),
                    text: finalResult.trim(),
                    timestamp: new Date().toISOString(),
                };
                console.log(`[DEBUG] Adding final transcription to list: "${finalResult}"`);
                setTranscription((prev) => [...prev, newEntry]);

                // Auto scroll to latest
                if (transcriptionRef.current) {
                    transcriptionRef.current.scrollTop = transcriptionRef.current.scrollHeight;
                }

                // Check if the transcription is a question
                if (isQuestion(finalResult)) {
                    console.log(`[DEBUG] Detected question: "${finalResult}"`);
                    // Clear previous response and start streaming
                    setAIResponse('');
                    streamOpenAIResponse(finalResult);
                } else {
                    console.log(`[DEBUG] Not a question: "${finalResult}"`);
                }
            }
        };

        recognitionRef.current.onend = () => {
            console.log('[DEBUG] Speech recognition ended. Restarting...');
            recognitionRef.current.start();
        };

        recognitionRef.current.start();

        return () => {
            if (recognitionRef.current) {
                recognitionRef.current.stop();
            }
        };
    }, [isSetupComplete, interviewSession]);

    // Initialize audio context and media stream
    useEffect(() => {
        if (!isSetupComplete || !interviewSession?.stream) return;

        audioContextRef.current = new AudioContext();
        mediaStreamRef.current = interviewSession.stream;
        const source = audioContextRef.current.createMediaStreamSource(mediaStreamRef.current);
        const gainNode = audioContextRef.current.createGain();
        source.connect(gainNode);
        gainNode.connect(audioContextRef.current.destination);

        return () => {
            if (audioContextRef.current) {
                audioContextRef.current.close();
            }
            if (mediaStreamRef.current) {
                mediaStreamRef.current.getTracks().forEach(track => track.stop());
            }
        };
    }, [isSetupComplete, interviewSession]);

    const handleSetupComplete = (sessionData) => {
        console.log('[DEBUG] Interview setup complete:', sessionData);
        setInterviewSession(sessionData);
        setIsSetupComplete(true);
    };

    const handleLeaveInterview = () => {
        console.log('[DEBUG] Leaving interview...');
        if (interviewSession) {
            if (interviewSession.stream) {
                interviewSession.stream.getTracks().forEach(track => track.stop());
            }
            if (interviewSession.displayStream) {
                interviewSession.displayStream.getTracks().forEach(track => track.stop());
            }
        }
        setIsSetupComplete(false);
        setInterviewSession(null);
        setTranscription([]);
        setAIResponse('');
    };

    if (!isSetupComplete) {
        return <SessionSetupFlow onComplete={handleSetupComplete} />;
    }

    return (
        <div className="min-h-screen bg-gray-50">
            {/* Header */}
            <div className="border-b bg-white">
                <div className="max-w-7xl mx-auto px-4 py-3 flex items-center justify-between">
                    <div className="flex items-center gap-3">
                        <h1 className="text-xl font-semibold">Live Interview</h1>
                        <span className="px-2 py-1 bg-orange-500 text-white text-sm rounded-full">
                            Premium
                        </span>
                        <div className="flex items-center gap-1 bg-secondary/20 px-3 py-1 rounded-full">
                            <span className="font-medium">00:00</span>
                        </div>
                    </div>
                    <div className="flex items-center gap-4">
                        <button className="p-2 hover:bg-gray-100 rounded-full">
                            <Settings className="w-5 h-5" />
                        </button>
                        <button
                            onClick={handleLeaveInterview}
                            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
                        >
                            Leave Interview
                        </button>
                    </div>
                </div>
            </div>

            {/* Main Content */}
            <div className="max-w-7xl mx-auto p-4">
                <div className="grid grid-cols-2 gap-4">
                    {/* Left Column - Video and Transcription */}
                    <div className="space-y-4">
                        {/* Video Feed */}
                        <div className="relative">
                            <video
                                ref={interviewSession?.videoRef}
                                autoPlay
                                playsInline
                                controls
                                className="w-full rounded-lg bg-black aspect-video"
                            />
                            <button
                                onClick={interviewSession?.togglePiP}
                                className="absolute bottom-4 right-4 px-3 py-1.5 bg-black/50 text-white rounded-lg hover:bg-black/70"
                            >
                                PiP View
                            </button>
                        </div>

                        {/* Transcription */}
                        <div className="bg-white rounded-lg shadow-sm p-4">
                            <div className="flex items-center justify-between mb-4">
                                <h2 className="font-medium">Live Transcription</h2>
                                <span className="flex items-center gap-1 text-green-600 text-sm">
                                    <span className="w-2 h-2 bg-green-500 rounded-full"></span>
                                    Active
                                </span>
                            </div>
                            <div
                                ref={transcriptionRef}
                                className="h-48 overflow-y-auto space-y-2 text-sm"
                            >
                                {transcription.map((entry) => (
                                    <div key={entry.id} className="p-2 rounded bg-gray-50">
                                        <p>{entry.text}</p>
                                        <span className="text-xs text-gray-500">
                                            {new Date(entry.timestamp).toLocaleTimeString()}
                                        </span>
                                    </div>
                                ))}
                                {interimTranscript && (
                                    <div className="p-2 rounded bg-gray-100 text-gray-500 italic">
                                        {interimTranscript}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Right Column - AI Copilot */}
                    <div className="bg-white rounded-lg shadow-sm">
                        <div className="p-4 border-b">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                    <Bot className="w-5 h-5" />
                                    <h2 className="font-medium">Interview Copilot™</h2>
                                </div>
                                <span className="flex items-center gap-1 text-green-600 text-sm">
                                    <span className="w-2 h-2 bg-green-500 rounded-full"></span>
                                    Ready
                                </span>
                            </div>
                        </div>
                        <div className="p-4 h-[calc(100vh-16rem)] overflow-y-auto">
                            {aiResponse ? (
                                <div className="text-gray-700 whitespace-pre-wrap">{aiResponse}</div>
                            ) : (
                                <div className="text-center text-gray-500 mt-8">
                                    Waiting for interview to begin...
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LiveInterviewManagement;

// import React, { useState, useEffect, useRef } from 'react';
// import { Settings, Bot, ScrollText, Brain, Camera } from 'lucide-react';
// import SessionSetupFlow from './SessionSetupFlow';

// // Helper function to format AI response with code blocks and auto-scroll
// const formatAIResponse = (text) => {
//     if (!text) return null;

//     // Split text by code block markers
//     const parts = text.split(/(```[a-z]*\n[\s\S]*?\n```)/g);

//     return parts.map((part, index) => {
//         // Check if this part is a code block
//         if (part.startsWith('```')) {
//             // Extract language and code
//             const [firstLine, ...rest] = part.split('\n');
//             const language = firstLine.replace('```', '');
//             const code = rest.slice(0, -1).join('\n'); // Remove last line (```)

//             return (
//                 <div key={index} className="my-4 rounded-lg overflow-hidden">
//                     {language && (
//                         <div className="bg-gray-800 px-4 py-1 text-gray-400 text-xs">
//                             {language}
//                         </div>
//                     )}
//                     <pre className="bg-gray-900 p-4 overflow-x-auto">
//                         <code className="text-gray-100 font-mono text-sm">
//                             {code}
//                         </code>
//                     </pre>
//                 </div>
//             );
//         }

//         // Regular text
//         return (
//             <p key={index} className="mb-4 last:mb-0">
//                 {part}
//             </p>
//         );
//     });
// };

// const LiveInterviewManagement = () => {
//     const [isSetupComplete, setIsSetupComplete] = useState(false);
//     const [interviewSession, setInterviewSession] = useState(null);
//     const [transcription, setTranscription] = useState([]);
//     const [questionHistory, setQuestionHistory] = useState([]);
//     const [interimTranscript, setInterimTranscript] = useState('');
//     const [aiResponse, setAIResponse] = useState('');
//     const [autoScroll, setAutoScroll] = useState(true);
//     const [isAnalyzing, setIsAnalyzing] = useState(false);
//     const [isScreenshotting, setIsScreenshotting] = useState(false);

//     const transcriptionRef = useRef(null);
//     const aiResponseRef = useRef(null);
//     const canvasRef = useRef(null);
//     const lastProcessedRef = useRef('');
//     const audioContextRef = useRef(null);
//     const mediaStreamRef = useRef(null);
//     const recognitionRef = useRef(null);
//     const sessionStartTime = useRef(Date.now());

//     // Enhanced logging function
//     const logEvent = (category, action, details) => {
//         const timestamp = new Date().toISOString();
//         const timeSinceStart = (Date.now() - sessionStartTime.current) / 1000;
//         console.log(`[${timestamp}] [${timeSinceStart.toFixed(2)}s] [${category}] ${action}`, details || '');
//     };

//     // Auto-scroll function for any container
//     const scrollToBottom = (containerRef) => {
//         if (containerRef?.current) {
//             containerRef.current.scrollTop = containerRef.current.scrollHeight;
//         }
//     };

//     // Effect to handle auto-scrolling for transcription
//     useEffect(() => {
//         if (autoScroll) {
//             scrollToBottom(transcriptionRef);
//             scrollToBottom(aiResponseRef);
//         }
//     }, [transcription, aiResponse, autoScroll]);

//     // Function to detect if a transcription is a question with enhanced logging
//     const isQuestion = (text) => {
//         logEvent('QUESTION_DETECTION', 'Analyzing text', { text });

//         const questionWords = ["what", "whats the", "how", "why", "when", "where", "who", "is", "are", "do", "does", "can", "could", "would", "should"];
//         const questionPhrases = ["tell me", "explain", "describe", "what if", "how about"];

//         if (text.trim().endsWith("?")) {
//             logEvent('QUESTION_DETECTION', 'Question mark detected', { text });
//             return true;
//         }

//         const firstWord = text.trim().toLowerCase().split(" ")[0];
//         if (questionWords.includes(firstWord)) {
//             logEvent('QUESTION_DETECTION', 'Question word detected', { word: firstWord, text });
//             return true;
//         }

//         if (questionPhrases.some(phrase => text.toLowerCase().includes(phrase))) {
//             logEvent('QUESTION_DETECTION', 'Question phrase detected', { text });
//             return true;
//         }

//         const words = text.trim().split(" ");
//         if (words.length > 3 && words[words.length - 1].toLowerCase() === "up") {
//             logEvent('QUESTION_DETECTION', 'Rising intonation detected', { text });
//             return true;
//         }

//         logEvent('QUESTION_DETECTION', 'Not a question', { text });
//         return false;
//     };

//     // Function to capture screenshot
//     const captureScreenshot = () => {
//         if (!interviewSession?.videoRef?.current) {
//             logEvent('SCREENSHOT', 'Failed - No video reference');
//             return;
//         }

//         setIsScreenshotting(true);
//         logEvent('SCREENSHOT', 'Initiating capture');

//         const video = interviewSession.videoRef.current;
//         const canvas = canvasRef.current;
//         const context = canvas.getContext('2d');

//         canvas.width = video.videoWidth;
//         canvas.height = video.videoHeight;

//         context.drawImage(video, 0, 0, canvas.width, canvas.height);

//         try {
//             canvas.toBlob((blob) => {
//                 const url = URL.createObjectURL(blob);
//                 const a = document.createElement('a');
//                 const filename = `interview-screenshot-${new Date().toISOString()}.png`;
//                 a.href = url;
//                 a.download = filename;
//                 document.body.appendChild(a);
//                 a.click();
//                 document.body.removeChild(a);
//                 URL.revokeObjectURL(url);

//                 logEvent('SCREENSHOT', 'Capture successful', { filename });
//                 setIsScreenshotting(false);
//             }, 'image/png');
//         } catch (error) {
//             logEvent('SCREENSHOT', 'Capture failed', { error: error.message });
//             setIsScreenshotting(false);
//         }
//     };

//     // Function to analyze full context
//     const analyzeFullContext = async () => {
//         setIsAnalyzing(true);
//         logEvent('ANALYSIS', 'Starting full context analysis');

//         const fullContext = transcription
//             .map(entry => `[${new Date(entry.timestamp).toLocaleTimeString()}] ${entry.text}`)
//             .join('\n');

//         logEvent('ANALYSIS', 'Context prepared', {
//             characterCount: fullContext.length,
//             entryCount: transcription.length
//         });

//         try {
//             const response = await fetch('https://api.openai.com/v1/chat/completions', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer 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`,

//                 },
//                 body: JSON.stringify({
//                     model: 'gpt-4o',
//                     messages: [
//                         {
//                             role: 'system',
//                             content: `You are analyzing a transcribed interview. Provide a comprehensive analysis, including:
//                             1) Key points discussed
//                             2) Notable insights
//                             3) Areas that need clarification
//                             4) Suggested follow-up questions
//                             When providing code examples:
//                             - Use markdown code blocks with language specification
//                             - Format code properly with consistent indentation
//                             - Add comments to explain complex parts`
//                         },
//                         {
//                             role: 'user',
//                             content: fullContext,
//                         },
//                     ],
//                     stream: true,
//                 }),
//             });

//             logEvent('ANALYSIS', 'API request sent');
//             const reader = response.body.getReader();
//             setAIResponse('Analyzing interview context...\n\n');
//             let result = '';

//             while (true) {
//                 const { done, value } = await reader.read();
//                 if (done) {
//                     logEvent('ANALYSIS', 'Stream completed');
//                     break;
//                 }

//                 const chunk = new TextDecoder().decode(value);
//                 const lines = chunk.split('\n').filter(line => line.trim() !== '');

//                 for (const line of lines) {
//                     if (line.startsWith('data: ')) {
//                         const data = line.replace('data: ', '');
//                         if (data === '[DONE]') break;

//                         try {
//                             const json = JSON.parse(data);
//                             const token = json.choices[0]?.delta?.content || '';
//                             result += token;
//                             setAIResponse((prev) => prev + token);
//                             logEvent('ANALYSIS', 'Token received', { length: token.length });
//                             scrollToBottom(aiResponseRef);
//                         } catch (error) {
//                             logEvent('ANALYSIS', 'Parse error', { error: error.message });
//                         }
//                     }
//                 }
//             }
//         } catch (error) {
//             logEvent('ANALYSIS', 'Analysis failed', { error: error.message });
//             setAIResponse('Failed to analyze the interview context. Please try again.');
//         } finally {
//             setIsAnalyzing(false);
//             logEvent('ANALYSIS', 'Analysis completed');
//             scrollToBottom(aiResponseRef);
//         }
//     };

//     // Function to stream OpenAI responses for questions
//     const streamOpenAIResponse = async (question) => {
//         logEvent('QUESTION', 'Processing question', { question });
//         setQuestionHistory(prev => [...prev, {
//             timestamp: new Date().toISOString(),
//             question,
//             detected: isQuestion(question)
//         }]);

//         try {
//             const response = await fetch('https://api.openai.com/v1/chat/completions', {
//                 method: 'POST',
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Bearer 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`,
//                 },
//                 body: JSON.stringify({
//                     model: 'gpt-4',
//                     messages: [
//                         {
//                             role: 'system',
//                             content: 'You are an AI assistant helping with an interview. Provide clear, concise responses to questions.'
//                         },
//                         {
//                             role: 'user',
//                             content: question,
//                         },
//                     ],
//                     stream: true,
//                 }),
//             });

//             logEvent('QUESTION', 'API request sent');
//             const reader = response.body.getReader();
//             let result = '';

//             while (true) {
//                 const { done, value } = await reader.read();
//                 if (done) {
//                     logEvent('QUESTION', 'Stream completed');
//                     break;
//                 }

//                 const chunk = new TextDecoder().decode(value);
//                 const lines = chunk.split('\n').filter(line => line.trim() !== '');

//                 for (const line of lines) {
//                     if (line.startsWith('data: ')) {
//                         const data = line.replace('data: ', '');
//                         if (data === '[DONE]') break;

//                         try {
//                             const json = JSON.parse(data);
//                             const token = json.choices[0]?.delta?.content || '';
//                             result += token;
//                             setAIResponse((prev) => prev + token);
//                             logEvent('QUESTION', 'Token received', { length: token.length });
//                             scrollToBottom(aiResponseRef);
//                         } catch (error) {
//                             logEvent('QUESTION', 'Parse error', { error: error.message });
//                         }
//                     }
//                 }
//             }
//         } catch (error) {
//             logEvent('QUESTION', 'Response failed', { error: error.message });
//             setAIResponse('Failed to stream response.');
//         } finally {
//             scrollToBottom(aiResponseRef);
//         }
//     };

//     // Initialize speech recognition
//     useEffect(() => {
//         if (!isSetupComplete || !interviewSession) return;

//         const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
//         if (!SpeechRecognition) {
//             logEvent('RECOGNITION', 'Not supported');
//             return;
//         }

//         recognitionRef.current = new SpeechRecognition();
//         recognitionRef.current.continuous = true;
//         recognitionRef.current.interimResults = true;
//         recognitionRef.current.lang = 'en-US';

//         recognitionRef.current.onresult = (event) => {
//             let interimResult = '';
//             let finalResult = '';

//             for (let i = event.resultIndex; i < event.results.length; i++) {
//                 const transcript = event.results[i][0].transcript;
//                 if (event.results[i].isFinal) {
//                     if (transcript !== lastProcessedRef.current) {
//                         finalResult = transcript;
//                         lastProcessedRef.current = transcript;
//                         logEvent('TRANSCRIPTION', 'Final result', { transcript });
//                     }
//                 } else {
//                     interimResult += transcript;
//                     logEvent('TRANSCRIPTION', 'Interim result', { transcript: interimResult });
//                 }
//             }

//             setInterimTranscript(interimResult);

//             if (finalResult) {
//                 const newEntry = {
//                     id: Date.now(),
//                     text: finalResult.trim(),
//                     timestamp: new Date().toISOString(),
//                     confidence: event.results[event.resultIndex][0].confidence
//                 };
//                 setTranscription(prev => [...prev, newEntry]);
//                 logEvent('TRANSCRIPTION', 'Entry added', newEntry);
//                 scrollToBottom(transcriptionRef);

//                 if (isQuestion(finalResult)) {
//                     logEvent('QUESTION', 'Question detected', { question: finalResult });
//                     setAIResponse('');
//                     streamOpenAIResponse(finalResult);
//                 }
//             }
//         };

//         recognitionRef.current.onend = () => {
//             logEvent('RECOGNITION', 'Recognition ended, restarting');
//             recognitionRef.current.start();
//         };

//         recognitionRef.current.start();
//         logEvent('RECOGNITION', 'Recognition started');

//         return () => {
//             if (recognitionRef.current) {
//                 recognitionRef.current.stop();
//                 logEvent('RECOGNITION', 'Recognition stopped');
//             }
//         };
//     }, [isSetupComplete, interviewSession]);

//     // Initialize audio context and media stream
//     useEffect(() => {
//         if (!isSetupComplete || !interviewSession?.stream) return;

//         audioContextRef.current = new AudioContext();
//         mediaStreamRef.current = interviewSession.stream;
//         const source = audioContextRef.current.createMediaStreamSource(mediaStreamRef.current);
//         const gainNode = audioContextRef.current.createGain();
//         source.connect(gainNode);
//         gainNode.connect(audioContextRef.current.destination);

//         logEvent('AUDIO', 'Audio context initialized');
//         return () => {
//             if (audioContextRef.current) {
//                 audioContextRef.current.close();
//                 logEvent('AUDIO', 'Audio context closed');
//             }
//             if (mediaStreamRef.current) {
//                 mediaStreamRef.current.getTracks().forEach(track => track.stop());
//                 logEvent('AUDIO', 'Media tracks stopped');
//             }
//         };
//     }, [isSetupComplete, interviewSession]);

//     const handleSetupComplete = (sessionData) => {
//         logEvent('SETUP', 'Setup completed', sessionData);
//         setInterviewSession(sessionData);
//         setIsSetupComplete(true);
//     };

//     const handleLeaveInterview = () => {
//         logEvent('SESSION', 'Leaving interview');
//         if (interviewSession) {
//             if (interviewSession.stream) {
//                 interviewSession.stream.getTracks().forEach(track => track.stop());
//             }
//             if (interviewSession.displayStream) {
//                 interviewSession.displayStream.getTracks().forEach(track => track.stop());
//             }
//         }
//         setIsSetupComplete(false);
//         setInterviewSession(null);
//         setTranscription([]);
//         setAIResponse('');
//         logEvent('SESSION', 'Interview ended');
//     };

//     // Session statistics logging
//     useEffect(() => {
//         logEvent('SESSION', 'Session statistics', {
//             transcriptionCount: transcription.length,
//             questionCount: questionHistory.length,
//             sessionDuration: (Date.now() - sessionStartTime.current) / 1000
//         });
//     }, [transcription.length, questionHistory.length]);

//     if (!isSetupComplete) {
//         return <SessionSetupFlow onComplete={handleSetupComplete} />;
//     }

//     return (
//         <div className="min-h-screen bg-gray-50">
//             {/* Hidden canvas for screenshots */}
//             <canvas ref={canvasRef} style={{ display: 'none' }} />

//             {/* Header */}
//             <div className="border-b bg-white">
//                 <div className="max-w-7xl mx-auto px-4 py-3 flex items-center justify-between">
//                     <div className="flex items-center gap-3">
//                         <h1 className="text-xl font-semibold">Live Interview</h1>
//                         <span className="px-2 py-1 bg-orange-500 text-white text-sm rounded-full">
//                             Premium
//                         </span>
//                         <div className="flex items-center gap-1 bg-secondary/20 px-3 py-1 rounded-full">
//                             <span className="font-medium">
//                                 {((Date.now() - sessionStartTime.current) / 1000 / 60).toFixed(2)} min
//                             </span>
//                         </div>
//                         <div className="flex items-center gap-1 bg-blue-100 text-blue-600 px-3 py-1 rounded-full">
//                             <span className="text-sm">
//                                 Questions: {questionHistory.length}
//                             </span>
//                         </div>
//                     </div>
//                     <div className="flex items-center gap-4">
//                         <button className="p-2 hover:bg-gray-100 rounded-full">
//                             <Settings className="w-5 h-5" />
//                         </button>
//                         <button
//                             onClick={handleLeaveInterview}
//                             className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
//                         >
//                             Leave Interview
//                         </button>
//                     </div>
//                 </div>
//             </div>

//             {/* Main Content */}
//             <div className="max-w-7xl mx-auto p-4">
//                 <div className="grid grid-cols-2 gap-4">
//                     {/* Left Column - Video and Transcription */}
//                     <div className="space-y-4">
//                         {/* Video Feed */}
//                         <div className="relative">
//                             <video
//                                 ref={interviewSession?.videoRef}
//                                 autoPlay
//                                 playsInline
//                                 controls
//                                 className="w-full rounded-lg bg-black aspect-video"
//                             />
//                             <div className="absolute bottom-4 right-4 flex gap-2">
//                                 <button
//                                     onClick={captureScreenshot}
//                                     disabled={isScreenshotting}
//                                     className="px-3 py-1.5 bg-black/50 text-white rounded-lg hover:bg-black/70 flex items-center gap-2 disabled:opacity-50"
//                                 >
//                                     <Camera className="w-4 h-4" />
//                                     {isScreenshotting ? 'Saving...' : 'Screenshot'}
//                                 </button>
//                                 <button
//                                     onClick={interviewSession?.togglePiP}
//                                     className="px-3 py-1.5 bg-black/50 text-white rounded-lg hover:bg-black/70"
//                                 >
//                                     PiP View
//                                 </button>
//                             </div>
//                         </div>

//                         {/* Transcription */}
//                         <div className="bg-white rounded-lg shadow-sm p-4">
//                             <div className="flex items-center justify-between mb-4">
//                                 <div className="flex items-center gap-3">
//                                     <h2 className="font-medium">Live Transcription</h2>
//                                     <span className="text-xs text-gray-500">
//                                         {transcription.length} entries
//                                     </span>
//                                 </div>
//                                 <span className="flex items-center gap-1 text-green-600 text-sm">
//                                     <span className="w-2 h-2 bg-green-500 rounded-full"></span>
//                                     Active
//                                 </span>
//                             </div>
//                             <div
//                                 ref={transcriptionRef}
//                                 className="h-64 overflow-y-auto space-y-2 text-sm scroll-smooth"
//                             >
//                                 {transcription.map((entry) => (
//                                     <div key={entry.id} className="p-2 rounded bg-gray-50">
//                                         <p className="flex items-start justify-between gap-2">
//                                             <span>{entry.text}</span>
//                                             {entry.confidence && (
//                                                 <span className="text-xs text-gray-400">
//                                                     {(entry.confidence * 100).toFixed(0)}%
//                                                 </span>
//                                             )}
//                                         </p>
//                                         <div className="flex items-center justify-between mt-1">
//                                             <span className="text-xs text-gray-500">
//                                                 {new Date(entry.timestamp).toLocaleTimeString()}
//                                             </span>
//                                             {isQuestion(entry.text) && (
//                                                 <span className="text-xs text-blue-600 bg-blue-50 px-2 py-0.5 rounded-full">
//                                                     Question
//                                                 </span>
//                                             )}
//                                         </div>
//                                     </div>
//                                 ))}
//                                 {interimTranscript && (
//                                     <div className="p-2 rounded bg-gray-100 text-gray-500 italic">
//                                         {interimTranscript}
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//                     </div>

//                     {/* Right Column - AI Copilot */}
//                     <div className="bg-white rounded-lg shadow-sm flex flex-col">
//                         <div className="p-4 border-b">
//                             <div className="flex items-center justify-between mb-4">
//                                 <div className="flex items-center gap-2">
//                                     <Bot className="w-5 h-5" />
//                                     <h2 className="font-medium">Interview Copilot™</h2>
//                                 </div>
//                                 <div className="flex items-center gap-4">
//                                     <button
//                                         onClick={() => setAutoScroll(!autoScroll)}
//                                         className={`flex items-center gap-1 px-2 py-1 rounded text-sm ${autoScroll ? 'bg-green-100 text-green-700' : 'bg-gray-100 text-gray-600'
//                                             }`}
//                                     >
//                                         <ScrollText className="w-4 h-4" />
//                                         {autoScroll ? 'Auto-scroll On' : 'Auto-scroll Off'}
//                                     </button>
//                                     <span className="flex items-center gap-1 text-green-600 text-sm">
//                                         <span className="w-2 h-2 bg-green-500 rounded-full"></span>
//                                         Ready
//                                     </span>
//                                 </div>
//                             </div>
//                             <button
//                                 onClick={analyzeFullContext}
//                                 disabled={isAnalyzing || transcription.length === 0}
//                                 className="w-full flex items-center justify-center gap-2 px-4 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors duration-200"
//                             >
//                                 <Brain className="w-5 h-5" />
//                                 {isAnalyzing ? 'Analyzing Context...' : 'Analyze Full Interview Context'}
//                             </button>
//                             <p className="text-xs text-gray-500 text-center mt-2">
//                                 Analyze the entire interview transcript for key insights and suggested follow-ups
//                             </p>
//                         </div>
//                         <div
//                             ref={aiResponseRef}
//                             className="flex-1 p-4 overflow-y-auto bg-gray-50 scroll-smooth"
//                         >
//                             {aiResponse ? (
//                                 <div className="prose max-w-none">
//                                     {formatAIResponse(aiResponse)}
//                                 </div>
//                             ) : (
//                                 <div className="text-center text-gray-500 mt-8">
//                                     Waiting for interview to begin...
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             {/* Toast notifications */}
//             {isScreenshotting && (
//                 <div className="fixed bottom-4 right-4 bg-gray-800 text-white px-4 py-2 rounded-lg shadow-lg flex items-center gap-2">
//                     <Camera className="w-4 h-4" />
//                     Saving screenshot...
//                 </div>
//             )}
//         </div>
//     );
// };

// export default LiveInterviewManagement;